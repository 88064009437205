/*
 * Payment Messages
 *
 * This contains all the text for the Payment component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Payment';

export default defineMessages({
  cash: {
    id: `${scope}.cash`,
    defaultMessage: 'Tiền Mặt',
  },
  card: {
    id: `${scope}.card`,
    defaultMessage: 'Thẻ Ghi Nợ / Thẻ Tín Dụng',
  },
  wallet: {
    id: `${scope}.wallet`,
    defaultMessage: 'Ví Điện Tử',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Điện Thoại',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Hủy',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Xác Nhận',
  },
  noAddress: {
    id: `${scope}.noAddress`,
    defaultMessage: 'Chưa có địa chỉ',
  },
  noPhone: {
    id: `${scope}.noPhone`,
    defaultMessage: 'Chưa có số điện thoại',
  },
  noMobile: {
    id: `${scope}.noMobile`,
    defaultMessage: 'Chưa có số di động',
  },
  paymentMethod: {
    id: `${scope}.paymentMethod`,
    defaultMessage: 'Phương thức thanh toán',
  },
  totalPrice: {
    id: `${scope}.totalPrice`,
    defaultMessage: 'Tiền cần thanh toán',
  },
  receivedMoney: {
    id: `${scope}.receivedMoney`,
    defaultMessage: 'Khách trả',
  },
  refunds: {
    id: `${scope}.refunds`,
    defaultMessage: 'Tiền trả lại',
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Quay lại',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Thanh toán',
  },
  fullscreen: {
    id: `${scope}.fullscreen`,
    defaultMessage: 'Toàn màn hình',
  },
  unFullscreen: {
    id: `${scope}.unFullscreen`,
    defaultMessage: 'Thoát chế độ toàn màn hình',
  },
  paySuccess: {
    id: `${scope}.paySuccess`,
    defaultMessage: 'Thanh toán hóa đơn thành công!',
  },
  insufficientFund: {
    id: `${scope}.insufficientFund`,
    defaultMessage: 'Số tiền nhận được không đủ để thanh toán hóa đơn này!',
  },
  confirmPaySessionOrder: {
    id: `${scope}.confirmPaySessionOrder`,
    defaultMessage: 'Thanh toán hóa đơn này?',
  },
});
