/**
 *
 * ButtonAsync
 *
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Button } from 'reactstrap';
import {LoadingCircle} from 'components/LoadingIndicator';

const div = <div></div>;

function ButtonAsync(props) {
	const ButtonContent = props.loading ? <LoadingCircle wrapper="button" /> : Children.toArray(props.children);
  return <Button {...props} >{ButtonContent}</Button>;
}

ButtonAsync.propTypes = {
	loading: PropTypes.number
};

export default ButtonAsync;
