import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { every } from 'lodash';

const NO_REDIRECT_PATHS = [
  '/login',
  '/password/forgot',
  '/password/forgot/notification',
  '/password/reset',
  '/account/activate',
  '/api/login/facebook',
  '/maintenance',
  '/error'
];

function RedirectOnHome(props) {
  const { location } = props;
  const willRedirect = every(NO_REDIRECT_PATHS, (path) => !location.pathname.includes(path));
  return willRedirect ? <Redirect to="/login" /> : null;
}

RedirectOnHome.propTypes = {
  location: PropTypes.any,
};

export default RedirectOnHome;