import styled from 'styled-components';

const StyledAvatar = styled.div`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
	transition: all 0.5s ease;
	${props => props.choice ? 'box-shadow: 0 0 0 2px blue !important;' : ''}
  border: 2px solid ${props => props.theme.borderAvatar};
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  &.large-avatar {
    width: 160px;
    height: 160px;
    span {
      line-height: 160px;
      color: ${props => props.theme.blackColor}
    }
  }
  &.medium-avatar {
    width: 110px;
    height: 110px;
    border: none;
    span {
      line-height: 110px;
      color: ${props => props.theme.blackColor}
    }
  }
  &.xmd-avatar {
    width: 70px;
    height: 70px;
    border: none;
    span {
      line-height: 70px;
      color: ${props => props.theme.blackColor}
    }
  }
  &.small-avatar {
    ${props => props.choice != null ?
    'width: 34px;height: 34px;border: 2px solid #fff;box-sizing: border-box;border-radius: 50%;'
    : 'width: 30px;height: 30px;border:none;'}
    span {
      line-height: 30px;
      color: ${props => props.theme.blackColor}
    }
  }
  > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    background: ${props => props.colour ? props.colour : props.theme.lightGray} no-repeat scroll center
      center / cover;
  }
  &:hover {
		>div:nth-child(2) {
			display: flex;
			align-items: center;
		}
	}
	&:hover, &:focus {
	  ${props => props.choice != null ? 'box-shadow: 0 0 0 3px #2a86cf !important;' : ''}
	}
`;

export default StyledAvatar;

export const StyledAvatarWrapper = styled.div`
	margin: 0 auto !important;
  position: relative;
	>div {
		margin-bottom: 0;
	}
`;

export const InnerCharacter = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 600;
`;

export const StyledAvatarOverlay = styled.div`
	display: none;
	vertical-align: middle;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	background-color: rgba(0,0,0,0.3) !important;
	transition: all 1s ease;
	>svg {
		color: rgba(255, 255, 255, 0.6);
		font-size: 20px;
		display: block;
		margin: auto;
	}
`;

