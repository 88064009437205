/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { 
  LOGIN, 
  LOGIN_FACEBOOK, 
  LOGIN_FACEBOOK_SUCCESS, 
  LOGIN_SUCCESS, 
  LOGIN_ERROR, 
  LOAD_AUTH, 
  LOAD_AUTH_SUCCESS, 
  SHOW_ALERT, 
  LOGOUT_ACTION, 
  LOGOUT_SUCCESS, 
  API_ERROR, 
  SET_SHOW_PROMPT,
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_CUSTOM_COMPLEX,
  GET_CUSTOM_COMPLEX_SUCCESS,
  SHOW_LOADING_OVERLAY,
} from './constants';

export function userLogin(loginInfo) {
  return {
    type: LOGIN,
    loginInfo,
  };
}

export function userLoginFb(){
  return {
    type: LOGIN_FACEBOOK,
  };
}

export function loginSuccess(response) {
  return {
    type: LOGIN_SUCCESS,
    response,
  };
}

export function loginFacebookSuccess() {
  return {
    type: LOGIN_FACEBOOK_SUCCESS,
  };
}

export function loginError() {
  return {
    type: LOGIN_ERROR,
  };
}

export function loadAuthInLocalStorage() {
  return {
    type: LOAD_AUTH,
  };
}

export function loadAuthSuccess(account) {
  return {
    type: LOAD_AUTH_SUCCESS,
    account,
  };
}

export function showAlert(message) {
  return {
    type: SHOW_ALERT,
    message,
  };
}

export function logoutAction() {
  return {
    type: LOGOUT_ACTION,
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function apiError(payload, status) {
  return {
    type: API_ERROR,
    payload,
    status,
  };
}

export function setShowRouterPrompt(show) {
  return {
    type: SET_SHOW_PROMPT,
    show,
  };
}

export function getCustomContact(params) {
  return {
    type: GET_CUSTOM_CONTACT,
    params,
  };
}

export function getCustomContactSuccess(response) {
  return {
    type: GET_CUSTOM_CONTACT_SUCCESS,
    response,
  };
}

export function getCustomProduct(params) {
  return {
    type: GET_CUSTOM_PRODUCT,
    params,
  };
}

export function getCustomProductSuccess(response) {
  return {
    type: GET_CUSTOM_PRODUCT_SUCCESS,
    response,
  };
}

export function getCustomOpportunity(params) {
  return {
    type: GET_CUSTOM_OPPORTUNITY,
    params,
  };
}

export function getCustomOpportunitySuccess(response) {
  return {
    type: GET_CUSTOM_OPPORTUNITY_SUCCESS,
    response,
  };
}

export function getConfigs(params) {
  return {
    type: GET_CONFIG,
    params,
  };
}

export function getConfigsSuccess(response) {
  return {
    type: GET_CONFIG_SUCCESS,
    response,
  };
}

export function getCustomComplex() {
  return {
    type: GET_CUSTOM_COMPLEX,
  };
}

export function getCustomComplexSuccess(response) {
  return {
    type: GET_CUSTOM_COMPLEX_SUCCESS,
    response,
  };
}

export function showLoadingOverlay(params) {
  return {
    type: SHOW_LOADING_OVERLAY,
    params,
  }
}