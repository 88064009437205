// import { take, call, put, select } from 'redux-saga/effects';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { startsWith } from 'lodash';
import globalMessages from 'containers/App/messages';
import { 
  showAlert, 
  loadAuthSuccess, 
  logoutSuccess,
  getConfigsSuccess,
  getCustomComplexSuccess,
} from 'containers/App/actions';
import {
  LOCAL_STORAGE_ACCOUNT_KEY,
  API_ERROR,
  LOAD_AUTH,
  LOGOUT_ACTION,
  GET_CONFIG,
  GET_CUSTOM_COMPLEX
} from 'containers/App/constants';
import { Messages } from '../../constants';
import { reactLocalStorage } from '../../utils';
import { API } from '../../network';

export function* apiError(args) {
  try {
    if (args.status == '503') {
      yield put(push({ pathname: '/maintenance' }));
    } else if (args.payload?.data?.error) {
      yield put(showAlert({ type: 'error', message: `${args.payload?.data?.error?.message??'Unknown Error'} (${args.payload?.data?.error?.code??'0'})` }));
    } else if (startsWith(`{args.status}`, '5') || startsWith(`{args.status}`, '4')){
      yield put(showAlert({ type: 'error', intlMessage: globalMessages.serverError }));
    } else {
      yield put(push({
        pathname: '/error',
        search: `?code=${args.status}`
      }));
    }
  } catch (err) {
    // continue regardless of error
  }
}

export function* loadAuthFromLocalStorage() {
  try {
    const account = yield call(
      reactLocalStorage.getObject,
      LOCAL_STORAGE_ACCOUNT_KEY,
    );
    yield put(loadAuthSuccess(account));
  } catch (err) {
    // continue regardless of error
  }
}

export function* logoutAccount() {
  try {
    const account = yield call(API.logout);
    yield call(reactLocalStorage.clear);
    yield put(logoutSuccess());
    yield put(showAlert({ type: 'success', intlMessage: globalMessages.logoutSuccess  }));

  }catch (err) {
    // continue regardless of error
  }
}

export function* getConfigs(args) {
  try {
    const response = yield call(API.getUserConfigs);
    yield put(getConfigsSuccess(response.data));
  } catch(error) {

  }
}

export function* getCComplex(args) {
  try {
    const resp = yield call(API.getCustomComplex);
    yield put(getCustomComplexSuccess(resp));
  } catch (err) {

  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(API_ERROR, apiError);
  yield takeLatest(LOGOUT_ACTION, logoutAccount);
  yield takeLatest(GET_CONFIG, getConfigs);
  yield takeLatest(GET_CUSTOM_COMPLEX, getCComplex);
  yield takeEvery(LOAD_AUTH, loadAuthFromLocalStorage);
  // See example in containers/HomePage/saga.js
}
