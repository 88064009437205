/*
 * FileUpload Messages
 *
 * This contains all the text for the FileUpload component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.FileUpload';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the FileUpload component!',
  },
  dropFile: {
    id: `${scope}.dropFile`,
    defaultMessage: 'Kéo tệp vào đây...',
  },

});
