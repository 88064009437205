/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';

import A from './A';
import StyledButton from './StyledButton';
import StyledButtonWelcome from './StyledButtonWelcome';
import Wrapper from './Wrapper';
import {
  Button as ButtonRS,
} from 'reactstrap';

const socialClass = {
  facebook: 'facebook',
  twitter: 'twitter',
  googlePlus: 'google-plus'
};

function Button(props) {
  // Render an anchor tag
  let button = (
    <A href={props.href} onClick={props.onClick}>
      {Children.toArray(props.children)}
    </A>
  );

  // If the Button has a handleRoute prop, we want to render a button
  if (props.handleRoute) {
    button = (
      <StyledButton onClick={props.handleRoute}>
        {Children.toArray(props.children)}
      </StyledButton>
    );
  }

  // If the Button has a social prop, we want to render a button
  if (props.socialType === 'true') {
    const className = `btn-social ${socialClass[props.name]}`;
    button = (
      <A
        href={props.href}
        onClick={props.onClick}
        className={className}
      >
        {Children.toArray(props.children)}
      </A>
    );
  }

  if (props.name === 'button') {
    button = (
      <StyledButtonWelcome className={props.className} onClick={props.onClick}>{Children.toArray(props.children)}</StyledButtonWelcome>
    );
  }

  if (props.name === 'menu') {
    button = (
      <StyledButtonWelcome className={props.className} onClick={props.onClick}>{Children.toArray(props.children)}</StyledButtonWelcome>
    );
    if(props.active) {
      const className = `active ${props.className}`;
      button = (
        <StyledButtonWelcome className={className} onClick={props.onClick}>{Children.toArray(props.children)}</StyledButtonWelcome>
      );
    }
  }

  return <Wrapper>{button}</Wrapper>;
}

Button.propTypes = {
  handleRoute: PropTypes.func,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  socialType: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
};

export default Button;
