import styled from 'styled-components';

const Close = styled.span`
  &.icon-close {
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      flex: none;
      width: 16px;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
    &:hover{
      svg{
       opacity: 0.8;
      }
    }
  }
`;

export default Close;
