/**
*
* SystemAlert
*
*/

import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Utils } from 'utils';
import 'react-toastify/dist/ReactToastify.css';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
  autoClose: 5000,
};

function SystemAlert({alerts}) {
  const intl = useIntl();

  useEffect(()=>{
    if (alerts){
      let message = alerts.message;
      if (alerts.intlMessage && alerts.intlMessage.id) {
        message = intl.formatMessage({ id: alerts.intlMessage.id });
        if (alerts.intlMessage.data) {
          message = Utils.stringInject(message, alerts.intlMessage.data) ;
        }
      }
      switch (alerts.type) {
        case 'success':
          toast.success(message, toastConfig);
          break;
        case 'error':
          toast.error(message, toastConfig);
          break;
        default:
          toast(message, toastConfig);
          break;
      }
    }
  }, [alerts]);

  return <ToastContainer />;
}

SystemAlert.propTypes = {

};

export default SystemAlert;
