/**
 *
 * FileItem
 *
 */

import React, { memo, useState } from 'react';
import { Progress, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { FormattedMessage } from 'react-intl';
import messages from './messages';
import LoadingIndicator from 'components/LoadingIndicator';

const iconMap = {
  default: 'file',
  image: 'file-image',
  video: 'file-video',
  audio: 'file-audio',
  pdf: 'file-pdf',
  doc: 'file-word',
  docx: 'file-word',
  xls: 'file-excel',
  xlsx: 'file-excel',
  ppt: 'file-powerpoint',
  pptx: 'file-powerpoint',
};

const fileTypeToIcon = file => {
  const fileNameSplitted = file.name.split('.');
  const fileExt = fileNameSplitted[fileNameSplitted.length - 1];
  if (_.startsWith(file.type, 'video')) return iconMap.video;
  else if (_.startsWith(file.type, 'audio')) return iconMap.audio;
  else if (_.startsWith(file.type, 'image')) return iconMap.image;
  else if (iconMap[fileExt]) return iconMap[fileExt];
  return iconMap.default;
};

const ItemWrapper = styled.div`
  margin: 8px;
  width: 100px;
  display: inline-block;
  text-align: center;
`;

const NameListWrapper = styled.div`
  margin: 8px 0;
  width: 100px;
  display: inline-block;
  text-align: left;
  margin-right: 15px;
`;

const ItemInner = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  background-size: cover;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #fff;
  ${props => props.background && props.background.preview && 
    `background-image: url(${props.background.preview})`
  }
`;

const CloseButton = styled.a`
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 6px;
  opacity: 0;
  transition: all .5s ease-in-out;
  svg{
    font-size: 14px;
    color: #ddd;
  }
  ${ItemInner}:hover & {
    opacity: 1;
  }
`;

const CloseItem = styled.a`
  color: ${props => props.theme.red};
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: -5px;
  transition: all .5s ease-in-out;
  svg{
    font-size: 10px;
    color: ${props => props.theme.red};;
  }
`;

const FileIcon = styled(FontAwesomeIcon)`
  color: #ddd;
  font-size: 40px!important;
  top: 30px;
  position: relative;
`;

const UploadProgress = styled(Progress)`
  width: 90px;
  position: absolute;
  left: 5px;
  bottom: 5px;
`;

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
`;

const FileNameWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  position: relative;
  padding-right: 15px;
`;

const InputDes = styled.textarea`
  width: 90px;
  min-height: 40px;
  position: absolute;
  left: 4px;
  bottom: 5px;
  background: rgba(255,255,255,0.7);
  border-radius: 2px;
  outline: 0;
  resize: none;
  border: 1px solid #ddd;
`;

const LoadingIcon = styled(LoadingIndicator)`
  top: 8px;
  position: absolute;
  left: 30px;
`;

const hasThumbnail = file => {
  return _.startsWith(file.type, 'image');
}

function FileItem({clickDelete, setDescription, file, type}) {
  const {progress, link, name, preview} = file;
  return (type && type == 'single') ? 
    (
      <ItemWrapper>
        <ItemInner background={hasThumbnail(file) ? file : null}>
          {(!hasThumbnail(file) || (hasThumbnail(file) && !preview)) && <FileIcon icon={fileTypeToIcon(file)} /> }
          {progress < 100 && <LoadingIndicator customStyle={`position: absolute; left: 30px; top: 8px`}/>}
          <CloseButton onClick={clickDelete}><FontAwesomeIcon icon="times"/></CloseButton>
        </ItemInner>
        <FileName>
          <span>{name}</span>
        </FileName>
      </ItemWrapper>
    ) : 
    (type && type == 'nameList') ?
    (
      <NameListWrapper>
        <FileNameWrapper>
          {link 
            ? <><a href={link} title={name} target="_blank">{name}</a><CloseItem onClick={clickDelete}><FontAwesomeIcon icon="times"/></CloseItem></>
            : <span>{name}<CloseItem onClick={clickDelete}><FontAwesomeIcon icon="times"/></CloseItem></span>
          }
        </FileNameWrapper>
      </NameListWrapper>
    ) :
    (
      <ItemWrapper>
        <ItemInner background={hasThumbnail(file) ? file : null}>
          {(!hasThumbnail(file) || (hasThumbnail(file) && !preview)) && <FileIcon icon={fileTypeToIcon(file)} /> }
        	{progress < 100 && <UploadProgress value={progress}>{progress}%</UploadProgress>}
          <CloseButton onClick={clickDelete}><FontAwesomeIcon icon="times"/></CloseButton>
          {progress == 100 && <InputDes placeholder="Nhập chú thích" onBlur={e => setDescription(e)}/>}
        </ItemInner>
        <FileName>
          {link 
            ? <a href={link} title={name}>{name}</a>
            : <span>{name}</span>
          }
        </FileName>
      </ItemWrapper>
    );
}

FileItem.propTypes = {};

export default memo(FileItem);
