import { LOCAL_STORAGE_AUTH_KEY } from 'containers/App/constants';
import { reactLocalStorage } from 'utils';

export function addAccessToken(initialConfig) {
  const config = initialConfig;
  const authKey = reactLocalStorage.get(LOCAL_STORAGE_AUTH_KEY);
  if (authKey && authKey.length) {
    const headers = { ...config.headers, Authorization: authKey };
    config.headers = headers;
  }
  return config;
}

export function onRejected(error) {
  return Promise.reject(error);
}
