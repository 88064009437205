import React, {memo} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import Animate from 'assets/images/vss-anim.gif';
import store from 'store';

import { makeSelectLoadingOverlay } from 'containers/App/selectors';
import { showLoadingOverlay } from 'containers/App/actions';

const DEFAULT_MESSAGE = 'Please wait...';

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  z-index: 9999;
`;

const LoadingContent = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 400px;
  text-align: center;
  background: #fff;
  padding: 20px 30px;
  border-radius: 10px;
`;

const LoadingOverlayContainer = ({loadingOverlay}) => {
  return loadingOverlay.show ?
          <Overlay>
            <LoadingContent>
              <img src={Animate} alt="Loading"/>
              <p>{loadingOverlay.message}</p>
            </LoadingContent>
          </Overlay>
          : null;
};

export const loadingOverlay = {};
loadingOverlay.show = function(message){
  store.dispatch(showLoadingOverlay({show: true, message: message || DEFAULT_MESSAGE}));
}
loadingOverlay.hide = function(){
  store.dispatch(showLoadingOverlay({show: false, message: ''}));
}

const mapStateToProps = createStructuredSelector({
  loadingOverlay: makeSelectLoadingOverlay(),
});

const withConnect = connect(
  mapStateToProps,
);

export default compose(
  withConnect,
  memo,
)(LoadingOverlayContainer);
