import React, { useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import { LOCAL_STORAGE_ACCOUNT_KEY } from 'containers/App/constants';
import reactLocalStorage from './localStorage';
import NumberFormat from 'react-number-format';

const Utils = {
  escapeString: function(string) {
    return string.replace(/[^A-Z0-9\.]+/ig, "_");
  },

  buildUrlWithParams: function(url, params, removeEncode){
    let ret = '';
    url += '?';
    for (var d in params) {
      if (params[d] || params[d] === 0) {
        if(removeEncode){
          ret += d + '=' + params[d] + '&';
        }else{
          if(_.isArray(params[d])) {
            _.forEach(params[d], (item) => { ret += encodeURIComponent(d) + '=' + encodeURIComponent(item) + '&'});
          }
          else {
            ret += encodeURIComponent(d) + '=' + encodeURIComponent(params[d]) + '&';
          }
        }
      }
    }
    ret = ret.replace(/&$/, '');
    return url + ret;
  },

  stringInject: function(str, arr) {
    if (typeof str !== 'string' || !(arr instanceof Array)) {
      return false;
    }

    return str.replace(/({\d})/g, function(i) {
      return arr[i.replace(/{/, '').replace(/}/, '')];
    })
  },

  isGrantedFeature: function(features) {
    const authentication = reactLocalStorage.getObject(LOCAL_STORAGE_ACCOUNT_KEY);
    if (authentication.roles && _.indexOf(authentication.roles, 'ROLE_ADMIN') >= 0){
      return true;
    }
    if (authentication.permissions && authentication.permissions.length){
      const permissions = _.map(authentication.permissions, (obj) => { return _.values(obj)[0]; });
      return _.every(features, function(feature){ return _.indexOf(permissions, feature) >= 0 });
    }

    return false;
  },

  generateFullName: function(obj) {
    return obj ? obj.displayName || `${obj.lastName} ${obj.firstName}` : ``;
  },

  formatNumberWithCommas: function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatMoneyToVND: function(value) {
    return <NumberFormat value={value ? value : 0} displayType={'text'} thousandSeparator={true} suffix={'đ'}/>;
  },

  limitHtml(text, limit) {
    var changedString = String(text).replace(/<[^>]+>/gm, '');
    return changedString.length > limit ? changedString.substr(0, limit - 1) + ' ...' : changedString;
  },
  
  translateNumberWords(text) {
    var finalText = '';
    var expectedTextStep1 = '';
    var expectedTextStep2 = '';
    var tens = '';
    var teens= '';
    var mapped = {
      one: 'một',
      two: 'hai',
      three: 'ba',
      four: 'bốn',
      five: 'năm',
      six: 'sáu',
      seven: 'bảy',
      eight: 'tám',
      nine: 'chín',
      ten: 'mười',
      eleven: 'mười một',
      twelve: 'mười hai',
      thirteen: 'mười ba',
      fifteen: 'mười lăm',
      twenty: 'hai mươi',
      thirty: 'ba mươi',
      forty: 'bốn mươi',
      fifty: 'năm mươi',
      hundred: 'trăm',
      thousand: 'nghìn',
      million: 'triệu',
      billion: 'tỷ',
      '-': ' '
    };
    var tensMapped = {
      sixty: 'sáu mươi',
      seventy: 'bảy mươi',
      eighty: 'tám mươi',
      ninety: 'chín mươi'
    };
    var teensMapped = {
      fourteen: 'mười bốn',
      sixteen: 'mười sáu',
      seventeen: 'mười bảy',
      eighteen: 'mười tám',
      nineteen: 'mười chín'
    };
    tens = text.replace(/sixty|seventy|eighty|ninety/gi, function(matched) {
      return tensMapped[matched];
    });
    teens = tens.replace(/sixteen|seventeen|eighteen|nineteen/gi, function(matched) {
      return teensMapped[matched];
    })
    expectedTextStep1 = teens.replace(/one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fifteen|twenty|thirty|forty|fifty|hundred|thousand|million|billion|-/gi, function(matched) {
      return mapped[matched];
    });
    //there ARE more better solutions => will refactor right after I found them
    if (_.includes(expectedTextStep1, 'hai mươi một')) {
      expectedTextStep2 = _.replace(expectedTextStep1, 'hai mươi một', 'hai mươi mốt');
    } else if (_.includes(expectedTextStep1, 'ba mươi một')) {
      expectedTextStep2 = _.replace(expectedTextStep1, 'ba mươi một', 'ba mươi mốt');
    } else if (_.includes(expectedTextStep1, 'bốn mươi một')) {
      expectedTextStep2 = _.replace(expectedTextStep1, 'bốn mươi một', 'bốn mươi mốt');
    } else if (_.includes(expectedTextStep1, 'năm mươi một')) {
      expectedTextStep2 = _.replace(expectedTextStep1, 'năm mươi một', 'năm mươi mốt');
    } else if (_.includes(expectedTextStep1, 'sáu mươi một')) {
      expectedTextStep2 = _.replace(expectedTextStep1, 'sáu mươi một', 'sáu mươi mốt');
    } else if (_.includes(expectedTextStep1, 'bảy mươi một')) {
      expectedTextStep2 = _.replace(expectedTextStep1, 'bảy mươi một', 'bảy mươi mốt');
    } else if (_.includes(expectedTextStep1, 'tám mươi một')) {
      expectedTextStep2 = _.replace(expectedTextStep1, 'tám mươi một', 'tám mươi mốt');
    } else {
      expectedTextStep2 = _.replace(expectedTextStep1, 'chín mươi một', 'chín mươi mốt');
    }
    if (_.includes(expectedTextStep2, 'hai mươi năm')) {
      finalText = _.replace(expectedTextStep2, 'hai mươi năm', 'hai mươi lăm');
    } else if (_.includes(expectedTextStep2, 'ba mươi năm')) {
      finalText = _.replace(expectedTextStep2, 'ba mươi năm', 'ba mươi lăm');
    } else if (_.includes(expectedTextStep2, 'bốn mươi năm')) {
      finalText = _.replace(expectedTextStep2, 'bốn mươi năm', 'bốn mươi lăm');
    } else if (_.includes(expectedTextStep2, 'năm mươi năm')) {
      finalText = _.replace(expectedTextStep2, 'năm mươi năm', 'năm mươi lăm');
    } else if (_.includes(expectedTextStep2, 'sáu mươi năm')) {
      finalText = _.replace(expectedTextStep2, 'sáu mươi năm', 'sáu mươi lăm');
    } else if (_.includes(expectedTextStep2, 'bảy mươi năm')) {
      finalText = _.replace(expectedTextStep2, 'bảy mươi năm', 'bảy mươi lăm');
    } else if (_.includes(expectedTextStep2, 'tám mươi năm')) {
      finalText = _.replace(expectedTextStep2, 'tám mươi năm', 'tám mươi lăm');
    } else {
      finalText = _.replace(expectedTextStep2, 'chín mươi năm', 'chín mươi lăm');
    }
    if (_.endsWith(finalText, 'triệu') || _.endsWith(finalText, 'tỷ')) {
      return _.upperFirst(finalText + ' đồng chẵn');
    } else {
      return _.upperFirst(finalText + ' đồng');
    }
  },

  getQueryParams: ( query ) => {
    return query && query!='?'
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {}
          )
      : {}
  },
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = _.debounce(() => {
      setSize([window.innerWidth, window.innerHeight]);
    }, 500);
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default Utils;
