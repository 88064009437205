/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import _ from 'lodash';
import { reactLocalStorage } from 'utils';
import { 
  LOGIN, 
  LOGIN_FACEBOOK, 
  LOGIN_FACEBOOK_SUCCESS, 
  LOGIN_SUCCESS, 
  LOGIN_ERROR, 
  LOAD_AUTH, 
  LOAD_AUTH_SUCCESS, 
  SHOW_ALERT, 
  API_ERROR, 
  LOGOUT_ACTION, 
  LOGOUT_SUCCESS, 
  SET_SHOW_PROMPT,
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_CUSTOM_COMPLEX,
  GET_CUSTOM_COMPLEX_SUCCESS,
  SHOW_LOADING_OVERLAY,
} from './constants';
import { LOCAL_STORAGE_ACCOUNT_KEY } from 'containers/App/constants';

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  account: false,
  systemAlert: false,
  apiError: false,
  loggedIn: false,
  showPrompt: false,
  customComplex: [],
  userConfigs: false,
  loadingOverlay: {
    show: false,
    message: '',
  }
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN: case LOGIN_FACEBOOK:
        draft.loading = true;
        draft.error = false;
        draft.account = false;
        break;

      case LOGIN_SUCCESS:
        draft.loading = false;
        draft.account = action.response.data;
        draft.loggedIn = true;
        break;

       case LOGIN_FACEBOOK_SUCCESS:
        draft.loggedIn = true;
        draft.account = reactLocalStorage.getObject(LOCAL_STORAGE_ACCOUNT_KEY);
        break;

      case LOGIN_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SHOW_ALERT:
        draft.systemAlert = action.message;
        break;

      case LOAD_AUTH:
        draft.account = false;
        break;

      case LOAD_AUTH_SUCCESS:
        draft.account = action.account;
        break;

      case API_ERROR:
        draft.apiError = action.payload;
        draft.loading = false;
        break;

      case LOGOUT_SUCCESS:
        draft.account = false;
        draft.loggedIn = false;
        draft.userConfigs = [];
        break;

      case SET_SHOW_PROMPT:
        draft.showPrompt = action.show;
        break;

      case GET_CUSTOM_COMPLEX:
        draft.customComplex = [];
        break;

      case GET_CUSTOM_COMPLEX_SUCCESS:
        draft.customComplex = action.response;
        break;

      case GET_CONFIG:
        draft.userConfigs = false;
        break;

      case GET_CONFIG_SUCCESS:
        if (!_.isEmpty(action.response)){
          draft.userConfigs = action.response;
        }
        break;

      case SHOW_LOADING_OVERLAY:
        draft.loadingOverlay = action.params;
        break;
    }
  });

export default appReducer;
