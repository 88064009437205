import React, { Children } from 'react';
import { throughDirect } from 'react-through';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import _ from 'lodash';

const FeatureRender = (props) => {
  const permissions = props.permissions && props.permissions.length ?
                      _.map(props.permissions, (obj) => { return _.values(obj)[0]; }) : [];
  const isAdmin = props.roles && _.indexOf(props.roles, 'ROLE_ADMIN') >= 0;
  return isAdmin ? (
    <>{Children.toArray(props.children)}</>
  ) : (
    <PermissibleRender
      userPermissions={permissions}
      requiredPermissions={props.requiredFeature}
    >
      {Children.toArray(props.children)}
    </PermissibleRender>
  )}

export default throughDirect('features')(FeatureRender);