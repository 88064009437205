/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ThroughProvider from 'react-through/lib/ThroughProvider';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
import library from './fontIcon';

import 'sanitize.css/sanitize.css';
import 'react-day-picker/lib/style.css';
import 'main.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/bootstrap/main.css';
import 'react-awesome-popover/build/index.css';
import 'react-toggle-switch/dist/css/switch.min.css';

import '@fullcalendar/core/main.js';
import '@fullcalendar/daygrid/main.js';
import '@fullcalendar/timegrid/main.js';
import '@fullcalendar/list/main.js';
import '@fullcalendar/bootstrap/main.js';
import 'font-awesome/scss/font-awesome.scss';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./assets/images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import store from './store';

// Import i18n messages
import { translationMessages } from './i18n';

// Create redux store with history
const MOUNT_NODE = document.getElementById('app');

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ThroughProvider>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ThroughProvider>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/vi.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}