/**
 *
 * Input
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input as InputRS } from 'reactstrap';
import NumberFormat from 'react-number-format';


const StyledInput = styled(InputRS)`
	background-color: ${props => props.theme.whiteColor};
  line-height: 20px;
  border: 1px solid ${props => props.theme.inputBorder};
  padding: 4px 7px 5px;
	&:focus{
		background-color: ${props => props.theme.inputBgFocus}; 
    border: 1px solid ${props => props.theme.inputOutline};
    outline: 0;
    box-shadow: 0 0 0 1px ${props => props.theme.inputOutline};
	}
  :read-only {
    background-color: ${props => props.theme.whiteColor};
  }
`;

const StyledCurrencyInput = styled(NumberFormat)`
  background-color: ${props => props.theme.whiteColor};
  line-height: 20px;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: .25rem;
  height: calc(1.5em + .75rem + 2px);
  border: 1px solid ${props => props.theme.inputBorder};
  padding: 4px 7px 5px;
  &:focus{
    background-color: ${props => props.theme.inputBgFocus}; 
    border: 1px solid ${props => props.theme.inputOutline};
    outline: 0;
    box-shadow: 0 0 0 1px ${props => props.theme.inputOutline};
  }
  :read-only {
    background-color: ${props => props.theme.whiteColor};
  }
  // ${props => !props.hide ? `visibility: hidden;` : null};
`;

function Input(props) {
  return (
  	<StyledInput hide={props.hide} {...props} />
  );
}

export function CurrencyField(props) {
  return (
    <StyledCurrencyInput thousandSeparator={true} {...props} />
  )
}

Input.propTypes = {
	icon: PropTypes.string
};

export default memo(Input);
