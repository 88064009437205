/**
 *
 * FixedMenu
 *
 */

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from 'reactstrap';
import { NavLink as RRNavLink, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import messagesNavBar from 'containers/NavBar/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faTasks, faCubes, faFileInvoiceDollar, faUser } from "@fortawesome/free-solid-svg-icons";
import StyledFixedMenu from './StyledFixedMenu';
import Button from 'components/Button';
import Draggable from 'react-draggable';

function FixedMenu(props) {
  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
  const [controlledPosition, setControlledPosition] = useState({ x: -400, y: 200 });

  const onControlledDrag = (e, position) => {
    const { x, y } = position;
    setControlledPosition({ x, y });
  }

  const onControlledDragStop = (e, position) => {
    onControlledDrag(e, position);
    onStop();
  }

  const handleDrag = (e, ui) => {
    setDeltaPosition({
      x: 0 + ui.deltaX
    });
    setDeltaPosition({
      y: 0 + ui.deltaY
    });
  }

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  }

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  }

  return (
    <StyledFixedMenu >
      <Draggable onDrag={handleDrag} onStop={onControlledDragStop} onStart={onStart}>
        <div>
          <div className="btn-fixed-menu">
            <Button onClick={() => { props.setIsOpen(!props.isOpen) }} name="menu" className="btn-menu" active={props.isOpen}><FontAwesomeIcon icon="plus" /></Button>
          </div>
          <div className="fixed-menu">
            <div className={props.classNameMenu}>
              <div className="display-table">
                <div className="display-tablecell">
                  <div className="ml-20">
                    <NavLink to="/contact/new" tag={RRNavLink}>
                      <Button name="button" className="btn-welcome menu" onClick={() => { props.setIsOpen(!props.isOpen) }}><FontAwesomeIcon icon={faUser} /><FormattedMessage {...messagesNavBar.customerText} /></Button>
                    </NavLink>
                    <NavLink to="/order/new" tag={RRNavLink}>
                      <Button name="button" className="btn-welcome menu" onClick={() => { props.setIsOpen(!props.isOpen) }}><FontAwesomeIcon icon={faFileInvoiceDollar} /><FormattedMessage {...messagesNavBar.orderText} /></Button>
                    </NavLink>
                    <NavLink to="/product/new" tag={RRNavLink}>
                      <Button name="button" className="btn-welcome menu" onClick={() => { props.setIsOpen(!props.isOpen) }}><FontAwesomeIcon icon={faCubes} /><FormattedMessage {...messagesNavBar.productText} /></Button>
                    </NavLink>
                  </div>
                </div>
                <div className="display-tablecell">
                  <div className="ml-20">
                    <NavLink to="/opportunity/new" tag={RRNavLink}>
                      <Button name="button" className="btn-welcome menu" onClick={() => { props.setIsOpen(!props.isOpen) }}><FontAwesomeIcon icon={faBullseye} /><FormattedMessage {...messagesNavBar.opportunityText} /></Button>
                    </NavLink>
                    <NavLink to="/task/new" tag={RRNavLink}>
                      <Button name="button" className="btn-welcome menu" onClick={() => { props.setIsOpen(!props.isOpen) }}><FontAwesomeIcon icon={faTasks} /><FormattedMessage {...messagesNavBar.staffText} /></Button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </StyledFixedMenu>
  );
}

FixedMenu.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  classNameMenu: PropTypes.string,
};

export default memo(FixedMenu);
