/*
 * NavBar Messages
 *
 * This contains all the text for the NavBar container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.NavBar';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the NavBar container!',
  },
  systemText: {
  	id: `${scope}.systemText`,
  	defaultMessage: 'Hệ thống',
  },
  accessHistoryText: {
  	id: `${scope}.accessHistoryText`,
  	defaultMessage: 'Lịch sử truy cập',
  },
  accountInformationText: {
  	id: `${scope}.accountInformationText`,
  	defaultMessage: 'Thông tin tài khoản',
  },
  accessPermissionsText: {
  	id: `${scope}.accessPermissionsText`,
  	defaultMessage: 'Phân quyền',
  },
  documentText: {
  	id: `${scope}.documentText`,
  	defaultMessage: 'Tài liệu',
  },
  internalChatText: {
  	id: `${scope}.internalChatText`,
  	defaultMessage: 'Trò chuyện nội bộ',
  },
  interfaceText: {
  	id: `${scope}.interfaceText`,
  	defaultMessage: 'Giao diện',
  },
  membershipText: {
  	id: `${scope}.membershipText`,
  	defaultMessage: 'Nhân sự',
  },
  productText: {
  	id: `${scope}.productText`,
  	defaultMessage: 'Sản phẩm',
  },
  customerText: {
  	id: `${scope}.customerText`,
  	defaultMessage: 'Khách hàng',
  },
  opportunityText: {
  	id: `${scope}.opportunityText`,
  	defaultMessage: 'Cơ hội',
  },
  orderText: {
    id: `${scope}.orderText`,
    defaultMessage: 'Đơn hàng',
  },
  staffText: {
    id: `${scope}.staffText`,
    defaultMessage: 'Công việc',
  },
  productText: {
    id: `${scope}.productText`,
    defaultMessage: 'Sản phẩm',
  },
  staffNavText: {
    id: `${scope}.staffNavText`,
    defaultMessage: 'Nhân viên (F4)',
  },
  warehouseText: {
  	id: `${scope}.warehouseText`,
  	defaultMessage: 'Kho hàng',
  },
  salesText: {
  	id: `${scope}.salesText`,
  	defaultMessage: 'Bán hàng',
  },
  policyText: {
  	id: `${scope}.policyText`,
  	defaultMessage: 'Chính sách',
  },
  jobText: {
  	id: `${scope}.jobText`,
  	defaultMessage: 'Công việc (F6)',
  },
  marketingText: {
  	id: `${scope}.marketingText`,
  	defaultMessage: 'Marketing',
  },
  campaignText: {
    id: `${scope}.campaignText`,
    defaultMessage: 'Chiến dịch',
  },
  emailMarketing: {
    id: `${scope}.emailMarketing`,
    defaultMessage: 'Email Marketing',
  },
  automationMarketing: {
    id: `${scope}.automationMarketing`,
    defaultMessage: 'Automation Marketing',
  },
  appointmentSchedule: {
    id: `${scope}.appointmentSchedule`,
    defaultMessage: 'Lịch hẹn',
  },
  logoutText: {
    id: `${scope}.logoutText`,
    defaultMessage: 'Đăng xuất',
  },
  contactText: {
    id: `${scope}.contactText`,
    defaultMessage: 'Liên hệ',
  },
  store: {
    id: `${scope}.store`,
    defaultMessage: 'kho',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Nhà cung cấp',
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: 'Phiếu nhập',
  },
  staff: {
    id: `${scope}.staff`,
    defaultMessage: 'Nhân viên',
  },
  store: {
    id: `${scope}.store`,
    defaultMessage: 'Kho',
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: 'Công Ty',
  },
  report: {
    id: `${scope}.report`,
    defaultMessage: 'Báo Cáo',
  },
  opportunity: {
    id: `${scope}.opportunity`,
    defaultMessage: 'Cơ hội',
  },
  homepage: {
    id: `${scope}.homepage`,
    defaultMessage: 'Trang chủ',
  },
  configuration: {
    id: `${scope}.configuration`,
    defaultMessage: 'Cấu hình',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Tài khoản',
  },
  changepassword: {
    id: `${scope}.changepassword`,
    defaultMessage: 'Đổi mật khẩu',
  },
  campaign: {
    id: `${scope}.campaign`,
    defaultMessage: 'Chiến dịch',
  },
  inventoryNotes: {
    id: `${scope}.inventoryNotes`,
    defaultMessage: 'Phiếu nhập kho',
  },
  bankAccount: {
    id: `${scope}.bankAccount`,
    defaultMessage: 'TK Ngân hàng',
  },
  contactDebts: {
    id: `${scope}.contactDebts`,
    defaultMessage: 'DS Hợp đồng',
  },
  payment: {
    id: `${scope}.payment`,
    defaultMessage: 'Phiếu thu',
  },
  receipt: {
    id: `${scope}.receipt`,
    defaultMessage: 'Phiếu chi',
  },
  transfer: {
    id: `${scope}.transfer`,
    defaultMessage: 'Chuyển khoản',
  },
  accountant: {
    id: `${scope}.accountant`,
    defaultMessage: 'Kế toán',
  },
  project: {
    id: `${scope}.project`,
    defaultMessage: 'Dự án',
  },
  sales: {
    id: `${scope}.sales`,
    defaultMessage: 'Bán hàng',
  },
  lookUp: {
    id: `${scope}.lookUp`,
    defaultMessage: 'Tra cứu',
  },
  pos: {
    id: `${scope}.pos`,
    defaultMessage: 'Điểm bán lẻ',
  },
  addPos: {
    id: `${scope}.addPos`,
    defaultMessage: 'Thêm điểm bán lẻ',
  },
  posSettings: {
    id: `${scope}.posSettings`,
    defaultMessage: 'Thiết lập điểm bán lẻ',
  },
  posProductCategorySettings: {
    id: `${scope}.posProductCategorySettings`,
    defaultMessage: 'Thiết lập ngành hàng',
  },
  storeAndOther: {
    id: `${scope}.storeAndOther`,
    defaultMessage: 'QL Kho & Nhập hàng',
  },
});
