/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const DEFAULT_LOCALE = 'en';

export const LOCAL_STORAGE_ACCOUNT_KEY = 'vss-account';
export const LOCAL_STORAGE_AUTH_KEY = 'vss-auth';

export const LOGIN = 'app/App/LOGIN';
export const LOGIN_FACEBOOK = 'app/App/LOGIN_FACEBOOK';
export const LOGIN_SUCCESS = 'app/App/LOGIN_SUCCESS';
export const LOGIN_FACEBOOK_SUCCESS = 'app/App/LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_ERROR = 'app/App/LOGIN_ERROR';
export const LOAD_AUTH = 'app/App/LOAD_AUTH';
export const LOAD_AUTH_SUCCESS = 'app/App/LOAD_AUTH_SUCCESS';
export const LOGOUT_ACTION = 'app/APP/LOGOUT_ACTION';
export const LOGOUT_SUCCESS = 'app/APP/LOGOUT_SUCCESS';

export const SHOW_ALERT = 'app/APP/SHOW_ALERT';
export const API_ERROR = 'app/APP/API_ERROR';
export const SET_SHOW_PROMPT = 'app/APP/SET_SHOW_PROMPT';

export const SHOW_LOADING_OVERLAY = 'app/APP/SHOW_LOADING_OVERLAY';

export const GET_CUSTOM_COMPLEX = 'app/APP/GET_CUSTOM_COMPLEX';
export const GET_CUSTOM_COMPLEX_SUCCESS = 'app/APP/GET_CUSTOM_COMPLEX_SUCCESS';

export const GET_CONFIG = 'app/APP/GET_CONFIG';
export const GET_CONFIG_SUCCESS = 'app/APP/GET_CONFIG_SUCCESS';
