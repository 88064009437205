/**
 *
 * Avatar
 *
 */

import React, { Children, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
// import styled from 'styled-components';

// import { FormattedMessage } from 'react-intl';
// import messages from './messages';
import StyledAvatar, { StyledAvatarOverlay, StyledAvatarWrapper, InnerCharacter } from './StyledAvatar';
import Wrapper from './Wrapper';
import avatarDefault from 'assets/images/demo-img.jpg';
import FileUpload from 'components/FileUpload';
import {LoadingCircle} from 'components/LoadingIndicator';

const avatarClass = {
  large: 'large-avatar',
  medium: 'medium-avatar',
  xmd: 'xmd-avatar',
  small: 'small-avatar'
};

function Avatar(
  {
    handleAssignee,
    choice,
    ...props
  }) {
  const [ configs, setConfigs ] = useState({});
  const [ filesUpload, setFilesUpload ] = useState([]);
  const inputRef = useRef(null);
  const [ didMount, setDidMount ] = useState(false);

  useEffect(() => setDidMount(true), [])

  const divStyle = {
    backgroundImage: props.url && props.url != null && props.url != '' ? `url('${props.url}')` : null,
  };

  const avatarUploaded = (file) =>{
    console.log(file);
    setFilesUpload(file);
  }

  const handleByAssignee = () => {
    handleAssignee(props.staffId);
  }

  useEffect(() => {
    let completed = true;
    if (filesUpload && filesUpload.length){
      _.forEach(filesUpload, file => {
        if (file.progress < 100)
          completed = false;
      });
      if (completed){
        didMount && props.onUploadCompleted && props.onUploadCompleted(_.last(filesUpload));
      }
    }
  }, [filesUpload]);

  let avatar = (
    <StyledAvatar className="avatar">
      <div style={divStyle}/>
      {Children.toArray(props.children)}
    </StyledAvatar>
  );

  let className = `${avatarClass[props.size]}`;

  if (props.size && !props.upload) {
    avatar = (
      <StyledAvatar className={className} colour={props.colour} choice={choice}>
      {props.url && props.url != null && props.url != '' ?
        <div style={divStyle} onClick={handleAssignee ? handleByAssignee : null} />
        :
        <div onClick={handleAssignee ? handleByAssignee : null}>
          <InnerCharacter>{props.title && props.title.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')}</InnerCharacter>
        </div>
      }
        {Children.toArray(props.children)}
      </StyledAvatar>
    );
  }
  else if (props.size && props.upload) {
    let className = `${avatarClass[props.size]}`;
    avatar = (
      <StyledAvatarWrapper>
        {filesUpload.length > 0 && filesUpload[0].progress < 100 ? <LoadingCircle customStyle={`position: absolute; z-index: 99; left: 50%; top: 50%; margin-left:-20px; margin-top: -20px`} /> : null}
        <FileUpload onUploadCompleted={avatarUploaded} trackStart={true} hideFileName={true}>
          <StyledAvatar className={className}>
            {props.url && props.url != null && props.url != '' ?
              <div style={divStyle} />
              :
              <div>
                <InnerCharacter>{props.title && props.title.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')}</InnerCharacter>
              </div>
            }
            <StyledAvatarOverlay >
              <FontAwesomeIcon icon="camera" />
            </StyledAvatarOverlay>
          </StyledAvatar>
        </FileUpload>
      </StyledAvatarWrapper>
    );
  }

  return <Wrapper title={props.title}>{avatar}</Wrapper>;
}

Avatar.propTypes = {
  size: PropTypes.string,
  url: PropTypes.string,
};

export default Avatar;
