export default {
	CONTACT: '/contact',
	CONTACT_NEW: '/contact/new',
	CONTACT_UPDATE: '/contact/update',
	CONTACT_DETAILS: '/contact/details',
	STAFF: '/staff',
	STAFF_NEW: '/staff/new',
	STAFF_UPDATE: '/staff/update',
	STAFF_DETAILS: '/staff/details',
	PRODUCT: '/product',
	PRODUCT_DETAILS: '/product/details',
	PRODUCT_IMPORT: '/products/import',
	PRODUCT_NEW: '/product/new',
	PRODUCT_SIMPLE_TABLE: '/product/list/simple',
	PRODUCT_ADVANCE_TABLE: '/product/list/advance',
	JOB: '/calendar',
	TASK_LIST: '/task/me',
	TASK_NEW: '/task/new',
	TASK_UPDATE: '/task/update',
	PERMISSION: '/permission',
	VENDOR: '/vendor',
	VENDOR_NEW: '/vendor/new',
	COUPON: '/coupon',
	ORDER: '/order',
	ORDER_NEW: '/order/new',
	ORDER_UPDATE: '/order/details',
	DASHBOARD: '/dashboard',
	VENDOR_UPDATE: '/vendor/update',
	SYSTEM: '/system',
	USER_SYSTEM: '/system/user',
	ROLE_SYSTEM: '/system/role',
	SYSTEM_MANAGEMENT: '/systems/management',
	PRODUCT_SIMPLE_NEW: '/product/simple/new',
	STORE: '/store',
	STORE_UPDATE: '/store/update',
	STORE_NEW: '/store/new',
	INVENTORY_NOTES: '/inventory/notes',
	COMPANY: '/company',
	COMPANY_NEW:'/company/new',
	COMPANY_UPDATE: '/company/update',
	OPPORTUNITY: '/opportunity',
	OPPORTUNITY_NEW: '/opportunity/new',
	OPPORTUNITY_UPDATE: '/opportunity/update',
	REPORT: '/report',
	EMAIL_REPORT: '/mail/report',
	ERROR: '/error',
	CONFIGURATION: '/configuration',
	CAMPAIGN: '/campaign',
	CAMPAIGN_NEW: '/campaign/new',
	PROJECT: '/project',
	PROJECT_NEW: '/project/new',
	PROJECT_UPDATE: '/project/update',
	PROJECT_TASK: '/project/tasks',
	PROJECT_LIST: '/project/list',
	BANK_ACCOUNT: '/bank/account',
	BANK_ACCOUNT_NEW: '/bank/account/new',
	BANK_ACCOUNT_UPDATE: '/bank/account/update',
	RECEIPT: '/payment/notes/pay',
	PAYMENT_BILL: '/payment/notes/receipt',
	CONTACT_DEBTS: '/contact/debts',
	TRANSFER: '/payment/notes/transfer',
	POS: '/pos',
	POS_NEW: '/pos/new',
	POS_SETTINGS_CATEGORIES: '/pos/settings/categories',
	POS_SETTINGS_CATEGORIES_SUGGESTIONS: '/pos/settings/categories/suggestions',
	SESSION: '/session',
	SALES: '/sales',
	SALES_DETAILS: '/sales/retail',
	LOOKUP: '/lookup',
	RESOURCES: '/resources',
	RESOURCES_NEW: '/resources/new',
	RESOURCES_UPDATE: '/resources/update',
	RMS: '/rms',
	UNIT_COMPUTE: '/unit/compute',
}
