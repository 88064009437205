export const defaultTheme = {
  primaryColor: '#2a86cf',
  gradientColor1: '#05aec5',
  gradientColor2: '#379fd2',
  blueColor: '#02a9e3',
  whiteColor: '#fff',
  blackColor: '#000',
  borderBlack: '#333',
  btnBlackColor: '#303030',
  borderAvatar: '#159380',
  btnFacebookColor: '#3a559f',
  btnTwitterColor: '#50abf1',
  btnGooglePlusColor: '#dc4e41',
  navbarTextColor: '#959595',
  btnAccountInfoColor: '#fefefe',
  greenColor: '#03a289',
  yellowColor: '#f8c45f',
  completeTextColor: '#273649',
  redColor: '#d55353',
  tabTextColor: '#807c7c',
  textShadowColor: '#ccc',
  borderTableColor: 'rgba(210, 210, 210, 0.4)',
  tableRowColor: '#eee',
  addcontactTextColor: '#404040',
  navbarBackgroundColor: '#1b5cc3',
  dropdownBackgroundColor: 'rgba(27, 92, 195, 0.8)',
  titleHeaderTableColor: '#807777',
  inputBg: '#ebecf0',
  focusOption: '#DEEBFF',
  inputBorder: '#dfe1e6',
  inputBgFocus: '#ffffff',
  inputOutline: '#4c9aff',
  bgBlueFocus: '#0062cc',
  bgGreenFocus: '#1e7e34',
  tableRowBgColor: 'rgb(240, 249, 252)',
  blue:    '#007bff',
  indigo:  '#6610f2',
  purple:  '#6f42c1',
  pink:    '#e83e8c',
  red:     '#dc3545',
  orange:  '#fd7e14',
  yellow:  '#ffc107',
  green:   '#28a745',
  teal:    '#20c997',
  cyan:    '#17a2b8',
  navy:    '#003366',
  gray:    '#A9A9A9',
  lightBlue: '#5488c7',
  lightGray: '#eee',
};
