/**
 * Global App Config
 */

const API_BASE_URL = 'https://test.crm.lhts.io/api/';

// const API_BASE_URL = 'https://uat.justice.bluebottle.vn/api';

export default {
  // App Details
  appName: 'VSSERP',

  // platform type 3: web
  platformType: 3,

  // URLs
  API_BASE_URL: API_BASE_URL,
};
