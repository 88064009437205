/**
 *
 * FileUpload
 *
 */

import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
// import PropTypes from 'prop-types';
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import {useDropzone} from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import messages from './messages';
import FileItem from './FileItem';
import { Wrapper, StyledDropZone, StyledListFile, AddNewItem } from './styles';
import { API } from 'network';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import store from 'store';

import { showAlert } from 'containers/App/actions';

const defaultAcceptedType = 'image/*, audio/*, video/*, .txt, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx';

function FileUpload(props) {
  const [ configs, setConfigs ] = useState({});
  const [ filesUpload, setFilesUpload ] = useState([]);
  const [ didMount, setDidMount ] = useState(false);
  const inputRef = useRef(null);

  function handleUpload (e){
    let files;
    if(e.target && e.target.files) {
      files = e.target.files;
    } else {
      files = e;
    }
    let filesUploadClone = [...filesUpload];
    const uuid = uuidv4();
    _.forEach(files, (file)=> {
      filesUploadClone.push({
        progress: 0,
        file,
        name: file.name,
        type: file.type,
        uuid,
        preview: _.startsWith(file.type, 'image') ? URL.createObjectURL(file) : null,
      });
    });
    setFilesUpload(filesUploadClone);
    if (props.callback && files.length){
      props.callback(files[0])
        .then( resp => {
          if (resp && resp.data && !resp.data.error){
            filesUploadClone[0].progress = 100;
            setFilesUpload(filesUploadClone);
            store.dispatch(showAlert({ type: 'success', message: resp.data.message }));
            props.onUploadCompleted && props.onUploadCompleted(filesUploadClone);
          } else {
            removeFile(0);
          }
        })
    } else {
      API.getS3Credential()
        .then( (resp) => {
          const config = resp.data.data;
          AWS.config.credentials = new AWS.Credentials(config.accessKeyId, config.secretAccessKey, config.sessionToken);
          AWS.config.update({region: config.region});
          setConfigs({
            bucketName: config.bucket,
            dirName: props.folder ? `${config.mediaFolder}/${props.folder}`: config.mediaFolder,
            region: config.region,
          });
        });
    }
  };

  const removeFile = i => {
    let filesUploadClone = [...filesUpload];
    filesUploadClone.splice(i, 1);
    setFilesUpload(filesUploadClone);
    props.onUploadCompleted(filesUploadClone);
  };

  const setFileDesciption = (e, index) => {
    let filesUploadClone = [...filesUpload];
    filesUploadClone[index].description = e.target.value;
    setFilesUpload(filesUploadClone);
  };

  const generateLink = file => {
    return `https://${configs.bucketName}.s3.amazonaws.com/${configs.dirName}/${file.uuid}/${file.name.replace(/ /g,'_')}`;
  };

  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
    });
    handleUpload(acceptedFiles);
  }

  const {acceptedFiles, getRootProps, getInputProps, isDragActive, open} = useDropzone({
    onDrop,
    noClick: props.multiple,
    multiple: props.multiple,
    accept: props.accept || defaultAcceptedType,
  });

  useEffect(() => setDidMount(true), [])

  useEffect(() => {
    if (filesUpload.length){
      if (props.trackStart){
        didMount && props.onUploadCompleted && props.onUploadCompleted(filesUpload);
      }
      const s3 = new S3();
      _.forEach(filesUpload, (file, i) => {
        if (file.started){
          return;
        }
        let params = {   
          Body: file.file,
          Bucket: configs.bucketName,
          Key: `${configs.dirName}/${file.uuid}/${file.name.replace(/ /g,'_')}`,
          ACL: 'public-read',
          ContentType: file.type,
        }; 
        let request = s3.putObject(params);
        request.on('httpUploadProgress', function (progress) {
          let progressPercent = +(progress.loaded / progress.total * 100).toFixed(0);
          let filesUploadClone = [...filesUpload];
          filesUploadClone[i].started = true;
          filesUploadClone[i].progress = progressPercent;
          if (progressPercent ==  100){
            filesUploadClone[i].link = generateLink(file);
            API.addAtachment({urls: [filesUploadClone[i].link]});
          }
          setFilesUpload(filesUploadClone);
        });
        request.send();
      }); 
    }
  }, [configs]);

  useEffect(() => {
    let completed = true;
    if (filesUpload && filesUpload.length){
      _.forEach(filesUpload, file => {
        if (file.progress < 100)
          completed = false;
      });
      if (completed){
        didMount && props.onUploadCompleted && props.onUploadCompleted(filesUpload);
      }
    }
  }, [filesUpload]);

  useEffect(() => {
    if(props.imageList && _.isArray(props.imageList)) {
      let imgFiles = [];
      _.forEach( props.imageList, (url, index) => {
        let splitedUrlFileName = url.split('/');
        imgFiles[index] = {
          name: _.last(splitedUrlFileName), 
          type: 'image/*',
          preview: `${url}`,
          progress: 100,
          link: url
        }
      });
      setFilesUpload(imgFiles);
    }else {
      if(props.imageList &&  !_.isArray(props.imageList)){
        let imageFiles =[];
        let splitedUrlFileName = props.imageList.split('/');
        imageFiles={
          name: _.last(splitedUrlFileName),
          type: 'image/*',
          preview: `${props.imageList}`,
          progress: 100,
          link: props.imageList
        }
        setFilesUpload(imageFiles);
      }
    }
  }, [])

  return (
    <>
      {props.dropupload 
        ? (
          <div>
            <StyledDropZone {...getRootProps()}>
              <input {...getInputProps({disabled: false})} />
              { filesUpload && filesUpload.length != 0
                ? null 
                : (
                  <div className="pt-5">
                    Drag and drop some files here or<br/>
                    {props.multiple 
                      ? <Button className="mt-3" color="primary" type="button" onClick={open}>Click Here</Button>
                      : <Button className="mt-3" type="button" color="primary">Click Here</Button>
                    }
                  </div>
                )
              }
              { filesUpload && filesUpload.length > 0 && props.multiple &&
                <StyledListFile>
                  {filesUpload.map((file, index) => (
                    <FileItem 
                      key={index} 
                      file={file}
                      clickDelete={() => removeFile(index)}
                      setDescription={e => setFileDesciption(e, index)}
                    />
                  ))}
                  <AddNewItem type="button" onClick={open}><FontAwesomeIcon icon="plus"/></AddNewItem>
                </StyledListFile>
              }
              { filesUpload && filesUpload.length > 0 && !props.multiple && 
                <FileItem 
                  type="single"
                  file={filesUpload[0]}
                  clickDelete={() => removeFile(0)}
                />
              }
            </StyledDropZone>
          </div>
        )
        : 
        (filesUpload && filesUpload.length > 0 && props.multiple) ? (
        <>
          <div onClick={() => inputRef.current.click()}>{props.children}</div>
          <input 
            ref={inputRef} 
            multiple={props.multiple}
            type="file"
            className="d-none"
            onChange={handleUpload}
          />
          { filesUpload && filesUpload.length > 0 && props.multiple && 
            <StyledListFile>
              {filesUpload.map((file, index) => (
                <FileItem 
                  type="nameList"
                  key={index} 
                  file={file}
                  clickDelete={() => removeFile(index)}
                />
              ))}
            </StyledListFile>
          }
        </>
        )
        : (
          <>
            <div onClick={() => inputRef.current.click()}>{props.children}</div>
            <input 
              ref={inputRef} 
              multiple={props.multiple}
              type="file"
              className="d-none"
              onChange={handleUpload}
            />
            { filesUpload && filesUpload.length > 0 && !props.multiple && !props.hideFileName && 
              <FileItem 
                type="nameList"
                file={filesUpload[0]}
                clickDelete={() => removeFile(0)}
              />
            }
          </>
        )
      }
    </>
  );
}

FileUpload.propTypes = {};

export default memo(FileUpload);
