import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faReadme,
} from '@fortawesome/free-brands-svg-icons';
import { 
  faCog, 
  faPencilAlt, 
  faCommentDots,
  faPlus,
  faCalendarAlt,
  faPollH,
  faAngleDown,
  faSearch,
  faTrashAlt,
  faEllipsisH,
  faEllipsisV,
  faMapMarkerAlt,
  faSortDown,
  faSortUp,
  faDownload,
  faWrench,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faImage,
  faPaperclip,
  faUsers,
  faPhone,
  faCheck,
  faTimes,
  faCloudUploadAlt,
  faSms,
  faSignature,
  faEnvelope,
  faMobileAlt,
  faVenusMars,
  faInfo,
  faUserFriends,
  faFlag,
  faFileUpload,
  faFile,
  faFileImage,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faCamera,
  faTag,
  faDragon,
  faShippingFast,
  faPhoneVolume,
  faPaperPlane,
  faHeadset,
  faCircle,
  faBullseye,
  faCubes,
  faTasks,
  faFileInvoiceDollar,
  faUser,
  faLongArrowAltLeft,
  faBackspace,
  faMinus,
  faAngleDoubleRight,
  faPrint,
  faLightbulb,
  faPhoneSquareAlt,
  faSave,
  faHistory,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faFacebookF, 
  faGooglePlusG, 
  faTwitter, 
  faCog, 
  faPencilAlt, 
  faCommentDots, 
  faPlus, 
  faCalendarAlt, 
  faPollH, 
  faAngleDown,
  faSearch,
  faTrashAlt,
  faEllipsisH,
  faEllipsisV,
  faMapMarkerAlt,
  faSortDown,
  faSortUp,
  faDownload,
  faWrench,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faImage,
  faPaperclip,
  faUsers,
  faPhone,
  faCheck,
  faTimes,
  faCloudUploadAlt,
  faSms,
  faSignature,
  faEnvelope,
  faMobileAlt,
  faVenusMars,
  faInfo,
  faUserFriends,
  faFlag,
  faFileUpload,
  faFile,
  faFileImage,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faCamera,
  faTag,
  faDragon,
  faShippingFast,
  faPhoneVolume,
  faPaperPlane,
  faHeadset,
  faCircle,
  faBullseye,
  faCubes,
  faTasks,
  faFileInvoiceDollar,
  faUser,
  faReadme,
  faLongArrowAltLeft,
  faBackspace,
  faMinus,
  faAngleDoubleRight,
  faPrint,
  faLightbulb,
  faPhoneSquareAlt,
  faSave,
  faHistory,
);

export default library;