import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faBackspace } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Row, Col, Button } from 'reactstrap';
import _ from 'lodash';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Fullscreen from "react-full-screen";
import store from 'store';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';

import BoxShadowWrapper from 'components/BoxShadowWrapper';
import ButtonAsync from 'components/ButtonAsync';
import Avatar from 'components/Avatar';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Input, { CurrencyField } from "components/Input";

import { showAlert } from 'containers/App/actions';
import globalMessages from 'containers/App/messages';
import { Utils } from 'utils';
import { API } from 'network';
import Routes from 'constants/routes';

import messages from './messages';

const PaymentWrapper = styled.div`
  padding: 15px;
  border-radius: 6px;
  box-shadow: 1px 2px 5px 1px rgba(206,206,206,1);
  .go-back {
    color: gray;
    margin-left: 15px;
    width: fit-content;
    transition: color .2s ease-in-out;
    &:hover, &:active {
      color: ${props => props.theme.primaryColor};
    }
  }
  h1 {
    text-align: center;
    padding-bottom: 30px;
    color: #5384d2;
  }
  .info {
    background-color: #fff;
    box-shadow: 1px 2px 5px 1px rgba(206,206,206,1);
    border-radius: 6px;
    margin-bottom: 20px;
    height: 140px;
    .contact-info {
      line-height: 1.5;
      padding-top: 20px;
      .display-name {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .phone {
        color: ${props => props.theme.primaryColor};
      }
      .phone-tip {
        color: ${props => props.theme.gray};
      }
    }
    .avatar {
      padding-top: 10px;
    }
  }
  .payment {
    background-color: #fff;
    box-shadow: 1px 2px 5px 1px rgba(206,206,206,1);
    border-radius: 6px;
    margin-bottom: 50px;
    h3 {
      padding-bottom: 15px;
      text-align: center;
      padding-top: 20px;
      color: #1b5cc3;
    }
    .btn {
      width: 100%;
      .btn-text {
        width: 100%;
        line-height: 35px;
        background-color: #fff;
        color: gray;
        margin-left: 15px;
        margin-bottom: 15px;
        border: 2px solid gray;
        border-radius: 6px;
        transition: all .2s ease-in-out;
        box-shadow: none !important;
        &:hover, &:focus, &:active {
          border: 2px solid ${props => props.theme.primaryColor};
          color: ${props => props.theme.primaryColor};
        }
      }
    }
  }
  
  .result-payment {
    .boxshadow-payment {
      background-color: #fff;
      box-shadow: 1px 2px 5px 1px rgba(206,206,206,1);
      border-radius: 6px;
      width: 100%;
      min-height: 63vh;
    }
    .result {
      width: 100%;
      height: 100px;
      text-align: right;
      margin: 35px 0px 30px 45px;
      font-size: 30px;
      color: blue;
      padding-right: 20px;
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      outline: none;
    }
    .input-caculate {
      margin-left: 22px;
      button {
        width: 70px;
        height: 60px;
        background-color: #ebebeb;
        color: #1b5cc3; 
        // color: #5384d2;
        border: 2px solid;
        margin-left: 20px;
        font-size: 19px;
        margin-bottom: 20px;
        border-radius: 6px;
      }
    }
    
    .label {
      background-color: #fff;
      box-shadow: 1px 2px 5px 1px rgba(206,206,206,1);
      width: 100%;
      display: block
      height: 220px;
      margin-bottom: 20px;
      border-radius: 6px;
      margin-left: -35px;
      .line-bottom {
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
        margin-left: 1px;
      }
      #refunds-price {
        border-bottom: none;
      }
      .label-text {
        padding-left: 5px;
        font-size: 15px;
        line-height: 75px;
        // border-bottom : 1px solid #e5e5e5;
        // width: 470px;
        color: #847b7b;
      }
      .total-price {
        text-align: right;
        line-height: 75px;
        padding-right: 15px;
        font-size: 20px;
      }
      #refunds {
        border-bottom: none;
      }
      #total-money {
        color: #4fc31b;
      }
      #price-customer {
        color: #847b7b;
      }
      #refunds-customer {
        color: red;
      }
    }
    .btn-submit {
      width: 100%;
      height: 60px;
      font-size: 20px;
      border-radius: 6px;
      margin-left: -35px;
    }
    #cancel {
      background-color: #fff;
      color: #1b5cc3;
      border: 1px solid;
    }
    #confirm {
      background-color: #1b5cc3;
      margin-left: -40px;
    }
  }
  .full-screen {
    line-height: 20px;
    color: ${props => props.theme.primaryColor};
    cursor: pointer;
    .material-icons {
      line-height: 20px;
      color: ${props => props.theme.primaryColor};
      cursor: pointer;
    }
  }
`;

function Payment({match, location, history}) {

  const intl = useIntl();
  const [receivedMoney, setReceivedMoney] = useState('0');
  const [totalPrice, setTotalPrice] = useState(0);
  const [refunds, setRefunds] = useState(0);
  const [billContact, setBillContact] = useState(null);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [newOrder, setNewOrder] = useState(null);
  const [posId, setPosId] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() =>{
    if (location.state && !_.isEmpty(location.state)) {
      setTotalPrice(location?.state?.totalPrice);
      setBillContact(location?.state?.contact);
      setCurrentSessionId(location?.state?.currentSessionId);
      setCurrentOrderId(location?.state?.currentOrderId);
      setPosId(location?.state?.posId);
      setResourceId(location?.state?.currentResourceId);
    }
  }, [location]);

  useEffect(() => {
    if (totalPrice && receivedMoney) {
      let refunds = parseFloat(receivedMoney) - totalPrice;
      setRefunds(refunds);
    }
  }, [receivedMoney, totalPrice]);

  const handleChangeReceivedMoney = (formattedValue, floatValue) => {
    setReceivedMoney(floatValue);
  };

  const handleClick = (e, value) => {
    switch (value) {
      case '1':
        let valueOne = receivedMoney + value;
        setReceivedMoney(valueOne.toString());
        break;
      case '2':
        let valueTwo = receivedMoney + value;
        setReceivedMoney(valueTwo.toString());
        break;
      case '3':
        let valueThree = receivedMoney + value;
        setReceivedMoney(valueThree.toString());
        break;
      case '100':
        let valueOneHundred = parseFloat(receivedMoney || 0) + 100000;
        setReceivedMoney(valueOneHundred.toString());
        break;
      case '4':
        let valueFour = receivedMoney + value;
        setReceivedMoney(valueFour.toString());
        break;
      case '5':
        let valueFive = receivedMoney + value;
        setReceivedMoney(valueFive.toString());
        break;
      case '6':
        let valueSix = receivedMoney + value;
        setReceivedMoney(valueSix.toString());
        break;
      case '200':
        let valueTwoHundred = parseFloat(receivedMoney || 0) + 200000;
        setReceivedMoney(valueTwoHundred.toString());
        break;
      case '7':
        let valueSeven = receivedMoney + value;
        setReceivedMoney(valueSeven.toString());
        break;
      case '8':
        let valueEight = receivedMoney + value;
        setReceivedMoney(valueEight.toString());
        break;
      case '9':
        let valueNine = receivedMoney + value;
        setReceivedMoney(valueNine.toString());
        break;
      case '500':
        let valueFiveHundred = parseFloat(receivedMoney || 0) + 500000;
        setReceivedMoney(valueFiveHundred.toString());
        break;
      case '0':
        let valueZero = receivedMoney + value;
        setReceivedMoney(valueZero.toString());
        break;
      case '00':
        let valueDoubleZero = receivedMoney + value;
        setReceivedMoney(valueDoubleZero.toString());
        break;
      case '000':
        let valueTripleZero = receivedMoney + value;
        setReceivedMoney(valueTripleZero.toString());
        break;
      case 'x':
        let newValueX = receivedMoney.toString().slice(0, - 1);
        setReceivedMoney(newValueX);
        break;
    }
  };

  const toggleFullscreen = () => {
    setFullscreen(true);
  };

  const createPaymentBill = async formData => {
    let postData = {
      pay: parseFloat(receivedMoney),
      posId: posId,
      posSessionOrderId: currentOrderId
    };
    try {
      let resp = await API.createPOSSessionPayment(postData);
      setNewOrder(resp?.data);
      store.dispatch(showAlert({ type: 'success', message: intl.formatMessage({ id: messages.paySuccess.id})}));
    } catch(error) {
      console.log(error);
    };
  };

  const confirmPaySessionOrder = formData => {
    const confirmMessage = intl.formatMessage({
      id: messages.confirmPaySessionOrder.id,
    });
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmationDialog
          onConfirm={() => createPaymentBill(formData)}
          onClose={onClose}
          title={intl.formatMessage({ id: globalMessages.confirmText.id })}
          message={confirmMessage}
          rejectText={intl.formatMessage({
            id: globalMessages.rejectText.id,
          })}
          confirmText={intl.formatMessage({
            id: globalMessages.acceptText.id,
          })}
        />
      ),
    });
  };

  useEffect(() => {
    if (newOrder && !_.isNull(newOrder)) {
      history.push({pathname: `${Routes.POS}/${posId}/session/${currentSessionId}/print/${newOrder?.order?.code}`, state: {newOrder, posId, currentSessionId}});
    }
  }, [newOrder]);

  return (
    <Fullscreen enabled={isFullscreen} onChange={isFullscreen => setFullscreen(isFullscreen)}>
      <PaymentWrapper>
        <div className="d-flex justify-content-between">
          <div className="go-back cursor-pointer" onClick={() => history.goBack()}><FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} /><FormattedMessage {...messages.goBack} /></div>
          {!isFullscreen ?
            <div>
              <div className="d-flex full-screen" onClick={toggleFullscreen}>
                <span className="mr-2"><FormattedMessage {...messages.fullscreen} /></span>
                <MaterialIcon icon="open_in_new" size="tiny"/>
              </div>
            </div>
          : null
          }
        </div>
        <h1><FormattedMessage {...messages.title} /></h1>
        <Row>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <Row className="info ml-4">
                  <Col md={4} className="avatar">
                    <Avatar size="medium" url={billContact?.url} />
                  </Col>
                  <Col md={8} className="pl-0">
                    <div className="contact-info">
                      <div title={Utils.generateFullName(billContact)} className="display-name">{Utils.generateFullName(billContact)}</div>
                      {
                        _.isEmpty(billContact?.addressLine1) && _.isEmpty(billContact?.addressLine2) && _.isEmpty(billContact?.addressState) && _.isEmpty(billContact?.addressCity) && _.isEmpty(billContact?.addressCountry) ?
                        <div><FormattedMessage {...messages.noAddress} /></div>
                        :
                        <div title={`${billContact?.addressLine1} ${billContact?.addressLine2} ${billContact?.addressState ?? ''} ${billContact?.addressCity ?? ''} ${billContact?.addressCountry ?? ''}`} className="address">{Utils.limitHtml(`${billContact?.addressLine1 ?? ''} ${billContact?.addressLine2 ?? ''} ${billContact?.addressState ?? ''} ${billContact?.addressCity ?? ''} ${billContact?.addressCountry ?? ''}`, 145)}</div>
                      }
                      {
                        _.isEmpty(billContact?.phone) ?
                        <div><FormattedMessage {...messages.noPhone} /></div>
                        :
                        <div title={billContact?.phone ?? '' } className="phone"><span className="phone-tip"></span><a href={`tel:${billContact?.phone ?? ''}`}>{billContact?.phone ?? ''}</a></div>
                      }
                      {
                        _.isEmpty(billContact?.mobile) ?
                        <div><FormattedMessage {...messages.noMobile} /></div>
                        :
                        <div title={billContact?.mobile ?? '' } className="phone"><span className="phone-tip"></span><a href={`tel:${billContact?.mobile ?? ''}`}>{billContact?.mobile ?? ''}</a></div>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col md={12} className="payment ml-4 pb-1">
              <h3><FormattedMessage {...messages.paymentMethod} /></h3>
              <Row className='btn active'>
                <Col md={12}>
                  <ButtonAsync
                    className="btn-text"
                    type="button"
                    color="primary"
                  >
                    <FormattedMessage {...messages.cash} />
                  </ButtonAsync>
                </Col>
                {/*<Col md={12}>
                  <ButtonAsync
                    className="btn-text"
                    type="button"
                    color="primary"
                  >
                    <FormattedMessage {...messages.card} />
                  </ButtonAsync>
                </Col>
                <Col md={12}>
                  <ButtonAsync
                    className="btn-text"
                    type="button"
                    color="primary"
                  >
                    <FormattedMessage {...messages.wallet} />
                  </ButtonAsync>
                </Col>
                <Col md={12}>
                  <ButtonAsync
                    className="btn-text"
                    type="button"
                    color="primary"
                  >
                    <FormattedMessage {...messages.phone} />
                  </ButtonAsync>
                </Col>*/}
              </Row>
            </Col>
          </Col>
          <Col md={8} className="result-payment">
            <div className="boxshadow-payment">
              <Row>
                <Col md={11}>
                  <CurrencyField
                    type="text"
                    className="result"
                    defaultValue={0}
                    min={0}
                    value={receivedMoney}
                    onValueChange={(values) => {
                      const { formattedValue, floatValue } = values;
                      handleChangeReceivedMoney(formattedValue, floatValue);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="input-caculate">
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "1")} value="1">1</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "2")} value="2">2</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "3")}>3</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "100")}>+100</ButtonAsync>
                  </div>
                  <div className="input-caculate">
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "4")} value="4">4</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "5")} value="5">5</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "6")} value="6">6</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "200")}>+200</ButtonAsync>
                  </div>
                  <div className="input-caculate">
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "7")} value="7">7</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "8")} value="8">8</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "9")} value="9">9</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "500")}>+500</ButtonAsync>
                  </div>
                  <div className="input-caculate">
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "0")} value="0">0</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "00")} value="00">00</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, "000")} value="000">000</ButtonAsync>
                    <ButtonAsync color="primary" onClick={(e) => handleClick(e, 'x')}><FontAwesomeIcon className="mr-2" icon={faBackspace} /></ButtonAsync>
                  </div>
                </Col>
                <Col md={6} className="title">
                  <div className="label">
                    <Row className="line-bottom">
                      <Col md={6}>
                        <div className="label-text col-md-12"><FormattedMessage {...messages.totalPrice} /></div>
                      </Col>
                      <Col md={6}>
                        <div className="total-price" id="total-money">{Utils.formatNumberWithCommas(totalPrice ?? 0)} đ</div>
                      </Col>
                    </Row>
                    <Row className="line-bottom">
                      <Col md={6}>
                        <div className="label-text"><FormattedMessage {...messages.receivedMoney} /></div>
                      </Col>
                      <Col md={6}>
                        <div className="total-price" id="price-customer">{Utils.formatNumberWithCommas(receivedMoney ?? 0)} đ</div>
                      </Col>
                    </Row>
                    <Row className="line-bottom" id="refunds-price">
                      <Col md={6}>
                        <div className="label-text" id="refunds"><FormattedMessage {...messages.refunds} /></div>
                      </Col>
                      <Col md={6}>
                        <div className="total-price" id="refunds-customer">{receivedMoney - totalPrice > 0 ? Utils.formatNumberWithCommas(receivedMoney - totalPrice ?? 0) + ' đ' : '0 đ'}</div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col md={6}>
                      <ButtonAsync onClick={() => history.goBack()} color="primary" className="btn-submit" id="cancel"><FormattedMessage {...messages.cancel} /></ButtonAsync>
                    </Col>
                    <Col md={6}>
                      <ButtonAsync 
                        onClick={() => {
                          if (refunds < 0) {
                            store.dispatch(showAlert({ type: 'error', message: intl.formatMessage({ id: messages.insufficientFund.id})}));
                          } else {
                            confirmPaySessionOrder();
                          }
                        }} 
                        color="primary" 
                        className="btn-submit" 
                        id="confirm">
                        <FormattedMessage {...messages.confirm} />
                      </ButtonAsync>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </PaymentWrapper>
    </Fullscreen>
  );
}

Payment.propTypes = {};

export default memo(Payment);
