export default {
  PING_URL: '/public/ping',
  LOGIN_URL: '/login',
  LOGOUT_URL: '/logout',
  LOGIN_FACEBOOK: '/login/facebook',
  ACTIVATE_ACCOUNT: '/account/activate',
  S3_CREDENTIAL: '/aws/session/credential',
  
  GET_CONTACT_STATUS: '/contact/status/count',
  SEARCH_COMPANY: '/company/search',
  
  STAFF: '/staff',
  STAFF_GROUP: '/group/staff',
  SEARCH_STAFF: '/staff/search',
  
  TASK_SEARCH: '/task/search',
  TASK_DELETE: '/task',
  TASK_DETAIL: '/task',
  TASK_UPDATE: '/task',
  TASK_GROUP: '/task/group',
  TASK_GROUP_PROJECT: '/task/group/project',
  TASK_GROUP_POSITION: '/task/group/position',
  TASK_GROUP_SEARCH : '/task/group/search',

  CHANGE_PASSWORD: '/user/pwd/change',

  CONTACT: '/contact',
  CONTACT_HIGHLIGHT: '/report/contact/highlight',
  CONTACT_COUNT_EVENT: '/event/count',
  CONTACT_COUNT_TASK: '/task/count',
  SEARCH_CONTACT: '/contact/search',
  CONTACT_COMMENT: '/communication',
  CONTACT_COMMENT_LIST: '/communication/search',
  CONTACT_TAG: '/contact/tag',
  CONTACT_DELETE_TAG: '/contact/delete/tag',
  CONTACT_FILTER: '/filter',
  CONTACT_STATUS: '/status/contact',
  CONTACT_GROUP: '/group/contact',
  CONTACT_GROUP_UPDATE: '/contact/group',
  CONTACT_DELETE_GROUP: '/contact/delete/group',
  CONTACT_ATTACHMENT: '/contact/attachment',
  CONTACT_CUSTOM: 'contact/custom',
  CONTACT_CUSTOM_SEARCH: 'custom/contact/search',
  CONTACT_SEQUENCE_LOAD: '/contact/sequence/next',
  CONTACT_ASSIGNEE_SEARCH: '/contact/assignee',
  CONTACT_VISIBILITY_SEARCH: '/contact/visibility',
  CONTACT_ACTIVITY:'contact/activity',
  CONTACT_ASSIGN: '/contact/assign',
  CONTACT_ORDER: '/contact/order',
  CONTACT_OPPORTUNITY: '/contact/opportunity',
  CONTACT_DEBT: '/debt/contact',
  
  JOB_TASK:'/task',
  SEARCH_TYPE_JOB:'/type/search',
  TYPE:'/type',
  SEARCH_PRIORITY_JOB: '/priority/search',
  SEARCH_OPPORTUNITY_JOB: '/opportunity/search',
  ATTACHMENT: '/attachment',
  UPLOAD_CONTACT_SIMPLE: '/contact/import/simple',
  UPLOAD_CONTACT_COMPLEX: '/contact/import/complex',
  UPLOAD_PRODUCT_SIMPLE: '/product/import/simple',
  UPLOAD_PRODUCT_COMPLEX: '/product/import/complex',
  UPLOAD_ORDER_COMPLEX: '/order/import/complex',
  UPLOAD_OPPORTUNITY_COMPLEX: '/opportunity/import/complex',

  PRODUCT: '/product',
  PRODUCT_SIMPLE: '/product/simple',
  PRODUCT_UPDATE_SKU: '/product/sku',
  PRODUCT_SIMPLE_SEARCH: '/product/simple/search',
  PRODUCT_SKU_SEARCH:'/product/sku/search',
  PRODUCT_SKU_PRICE_UPDATE: '/product/sku/price',
  PRODUCT_GENERATE_SKU: '/product/sku/generate',
  PRODUCT_CUSTOM_SEARCH: 'custom/product/search',
  PRODUCT_CUSTOM: 'product/custom',
  PRODUCT_CATEGORIES: '/product/categories',
  PRODUCT_EXPORT: '/product/export',
  PRODUCT_SEQUENCE: '/product/sequence/next',

  INVENTORY_URL: '/inventory',
  INVENTORY_SEARCH: '/inventory/search',
  INVENTORY_IMPORT: '/inventory/import',
  INVENTORY_IMPORT_SEARCH: '/inventory/import/search',
  INVENTORY_IMPORT_DETAIL: '/inventory/import/details',
  INVENTORY_IMPORT_SEQUENCE_NEXT: '/inventory/import/sequence/next',
  INVENTORY_APPROVE: '/inventory/approved',
  INVENTORY_CANCEL: '/inventory/cancel',

  SUPPLIER:'/supplier',
  SUPPLIER_SEARCH: '/supplier/search',
  SUPPLIER_TYPE_SEARCH:'/supplier/type/search',

  CONTACT_EXPORT:'/contact/export',
  PUBLIC_EXPORT: '/public/export',
  USER_SEARCH:'/user/search',
  ROLE_SEARCH:'/role/search',
  GET_FEATURE: '/feature',
  GET_FEATURE_GROUP: '/feature/group',
  ROLE_PERMISSION: '/role/permission',
  USER_ROLE_SEARCH: '/user/role/search',
  ROLE: '/role',
  USER_ROLE: '/user/role',
  USER_PERMISSION:'/user/permission',

  ORDER_CREATE: '/order',
  ORDER_SEARCH: '/order/search',
  ORDER_CANCEL: 'order/cancel',
  ORDER_SEQUENCE: '/order/sequence/next',
  ORDER_APPROVE: 'order/approved',
  ORDER_DETAIL: '/order/details',
  ORDER_CUSTOM_SEARCH: '/custom/order/search',
  ORDER_EXPORT: '/order/export',
  ORDER_PREVIEW: '/order/preview',

  ADD_SIMPLE_PRODUCT: '/product',

  REPORT_PRODUCT: '/report/product',
  REPORT_PRODUCT_SALE: '/report/product/sales',
  REPORT_CONTACT: '/report/contact',
  REPORT_CONTACT_BY_SOURCE: '/report/contact/source',
  REPORT_MONTHLY_HIGHLIGHT: '/report/monthly/highlight',
  REPORT_REVENUE: '/report/revenue',
  REPORT_STOCK: '/stock',

  COMPANY_SEARCH: '/company/search',
  COMPANY_CREATE: '/company',
  COMPANY_UPDATE: '/company',
  COMPANY: '/company',

  CAMPAIGN_URL: '/campaign',
  CAMPAIGN_SEARCH: '/campaign/search',
  CAMPAIGN_ASSIGN: '/campaign/assign',

  OPPORTUNITY_SEARCH: '/opportunity/search',
  OPPORTUNITY_URL: '/opportunity',
  OPPORTUNITY_SEQUENCE: '/opportunity/sequence/next',
  OPPORTUNITY_CUSTOM_SEARCH: '/custom/opportunity/search',
  OPPORTUNITY_CANCEL: '/opportunity/cancel',
  OPPORTUNITY_EXPORT: '/opportunity/export',

  GET_APPLICATION_CONFIG: '/application/config',
  GET_APPLICATION_CONFIG_DETAILS: '/application/config/details',
  
  GET_CUSTOM_COMPLEX: '/custom/complex',
  STAFF_PWD_URL: '/staff/pwd',
  USER_PROFILE: '/user/profile',
  EMAIL_TEMPLATE_URL : '/email/template',
  EMAIL_TEMPLATE_SEARCH: '/email/template/search',

  BANK_ACCOUNT_CREATE: '/bank/account',
  BANK_ACCOUNT_SEARCH: '/bank/account/search',
  BANK_ACCOUNT_DETAIL: '/bank/account',
  BANK_ACCOUNT_URL: '/bank/account',
  BANK_ACCOUNT_DELETE: '/bank/account',

  RECEIPT: '/payment/notes/pay',
  RECEIPT_SEQUENCE: '/payment/pay/sequence/next',
  PAYMENT: '/payment/notes/search',
  PAYMENT_BILL: '/payment/notes/receipt',
  PAYMENT_BILL_SEQUENCE: '/payment/receipt/sequence/next',
  TRANSFER : '/payment/notes/move',
  TRANSFER_SEQUENCE: '/payment/move/sequence/next',

  CONTACT_DEBTS: '/debt/search',
  CONTACT_DEBTS_TRANSACTION: '/debt/transaction/search',

  PROJECT_SEARCH: '/project/search',
  PROJECT_CREATE: '/project',
  PROJECT_DETAILS: '/project',
  PROJECT_DELETE: '/project',
  PROJECT_ASSIGN: '/project/assign',

  POS_URL: '/pos',
  POS_SEARCH: '/pos/search',
  POS_SIMPLE_SEARCH: '/pos/search/simple',
  POS_SEQUENCE: '/pos/sequence/next',
  POS_ASSIGN: '/pos/assign',
  POS_CLOSE_SESSION: '/pos/session/close',
  POS_PAYMENT: '/pos/payment',
  POS_PRODUCT_CATEGORY_SEARCH: '/product/category/search',

  POS_SESSION_URL: '/pos/session',
  POS_SESSION_SEARCH: '/pos/session/search',
  POS_SESSION_SEQUENCE: '/pos/session/sequence/next',
  POS_SESSION_NEAREST: '/pos/session/nearest',
  POS_SESSION_CONFIRM: '/pos/session/confirm',
  POS_SESSION_CLOSE: '/pos/session/close',
  POS_SESSION_ORDER: '/pos/session/orders',
  POS_SESSION_SEARCH_ORDER: '/pos/session/order/search',
  POS_SESSION_ORDER_URL: '/pos/session/order',
  POS_SESSION_UPDATE_CONTACT: '/pos/session/order/contact',
  POS_SESSION_UPDATE_TAX: '/pos/session/order/tax',
  POS_SESSION_UPDATE_DISCOUNT: '/pos/session/order/discount',
  POS_SESSION_ORDER_DETAILS: '/pos/session/order/details',
  POS_SESSION_UPDATE_PRODUCT_SKU: '/pos/session/order/details/sku',
  POS_SESSION_UPDATE_PRODUCT_QUANTITY: '/pos/session/order/details/quantity',

  CATEGORY_PRESET: '/product/category/preset',
  CREATE_CATEGORY_PRESET: '/product/category',

  CUSTOMFIELD: '/custom',
  CUSTOMFIELD_DETAIL: '/custom/field',
  
  FORGOT_PASSWORD: '/user/pwd/forgot',
  PASSWORD_RESET: '/user/pwd/reset',

  EXPORT_PROGRESS: '/export/progress',

  MAIL_REPORT: '/trackr/search',
  
  RESOURCE: '/resource/search',
  RESOURCE_CREATE: '/resource',
  RESOURCE_DETAIL : '/resource',

  RESOURCE_ORDER: '/resource/order',
  RESOURCE_ORDER_CONTACT: '/resource/order/contact',
  RESOURCE_ORDER_DISCOUNT: '/resource/order/discount',
  RESOURCE_ORDER_TAX: '/resource/order/tax',
  RESOURCE_ORDER_SEARCH: '/resource/order/search',

  RESOURCE_ORDER_DETAIL: '/resource/order/details',
  RESOURCE_ORDER_DETAIL_QUANTITY: '/resource/order/details/quantity',
  RESOURCE_ORDER_DETAIL_SKU: '/resource/order/details/sku',
};
