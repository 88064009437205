import { reactLocalStorage } from 'utils';
import { apiError } from 'containers/App/actions';
import store from 'store';

const UnauthorizeStatusCode = 401;
const ForbiddenStatusCode = 403;
const InternalServerError = 500;
const ServerMaintenanceError = 503;

export function onFullfilled(response) {
  return Promise.resolve(response);
}

export function onRejected(error) {
  if (error) {
    const { response } = error;
    store.dispatch(apiError(response, response.status));
  }
}
