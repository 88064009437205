import { css } from 'styled-components';

const buttonStyles = css`
  display: inline-block;
  box-sizing: border-box;
  padding: 0.25em 2em;
  text-decoration: none;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-weight: bold;
  font-size: 16px;
  border: 2px solid #41addd;
  color: #41addd;

  &:active {
    background: #41addd;
    color: #fff;
  }
  &.btn-social {
    border-radius: 20px;
    width: 100%;
    padding: 0;
    font-size: 20px;
    border: none;
    padding: 2px 0;
    > svg {
      color: ${props => props.theme.whiteColor};
    }
  }
  &.button {
    padding: 7px 20px;
    border-radius: 30px;
    background-color: ${props => props.theme.btnAccountInfoColor};
    border: 2px solid;
    svg {
      font-size: 20px;
      vertical-align: middle;
    }
    span {
      text-transform: uppercase;
      padding-left: 10px;
      font-size: 13px;
    }
  }
  &.facebook {
    background-color: ${props => props.theme.btnFacebookColor};
  }
  &.twitter {
    background-color: ${props => props.theme.btnTwitterColor};
  }
  &.google-plus {
    background-color: ${props => props.theme.btnGooglePlusColor};
  }
`;

export default buttonStyles;
