import { createGlobalStyle } from 'styled-components';
import '@material/react-material-icon/dist/material-icon.css';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 12px;
    line-height: 1.5;
    overflow-x: hidden;
  }

  html {
    font-size: 12px;
  }

  body {
    font-family: 'Avenir Next',BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif !important;
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }
  .mb-margin {
    @media(max-width: 767px) {
      margin-bottom: 1rem;
    }
  }
  
  .fw-100 {
    width: 100%;
  }

  .DayPickerInput{
    width: 100%;
  }

  .fullscreen-enabled {
    background-color: #fafafa;
    padding: 100px 0 0 20px;
  }

  .box-shadow {
    box-shadow: 1px 2px 5px 1px rgba(206,206,206,0.5);
  }

  .cursor-pointer{
    cursor: pointer;
  }

  .font-weight-semibold{
    font-weight: 600;
  }

  .text-dark{
    color: #333;
  }

  @media(min-width: 1600px) {
    .container {
      max-width: 1540px !important;
    }
  }
  @media(min-width: 350px) {
    .visiable-xs {
      display: block;
    }
    .hidden-xs {
      display: none;
    }
  }
  @media(min-width: 768px) {
    .visiable-xs {
      display: none;
    }
    .hidden-xs {
      display: block;
    }
  }
  .rdw-editor-toolbar {
    font-size: 11px !important;
  }
  /* Content */
  .rap-popper-div {
    -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
    -moz-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  }

  /* Arrow */
  .rap-popper-div-arrow[data-placement*="bottom"] {
      margin-top: -0.6em;
  }
  .rap-popper-div-arrow[data-placement*="bottom"]::before {
    border-width: 0 1em 0.6em 1em;
    border-color: transparent transparent #e3e3e3 transparent;
  }

  .rap-popper-div-arrow[data-placement*="bottom"]::before {
    border-width: 0 1em 0.6em 1em;
    border-color: transparent transparent #fff transparent;
    filter: drop-shadow(0px 0px 0px #000);
  }  
  .modal-dialog {
    margin-top: 100px;
  }
  .rdt_TableCol, &.rdt_TableCell {
    padding-left: 8px;
    padding-right: 8px;
  }
  .OrderDetail, .opportunityDetail, .addAssignee {
    width: 80% !important;
    max-width: inherit;
    .comment-box-wrapper {
      .comment-box {
        height: 150px !important;
        border: 1px solid ${props => props.theme.borderTableColor} !important;
        padding: 1rem !important;
        border-radius: 2px !important;
        background-color: ${props => props.theme.whiteColor};
      }
    }
    .duWXem {
      margin-left: 0;
    }
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .col-md-1-5, .col-md-4-5, .col-md-3-5, .col-md-2-5 {
    position: relative;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
  @media (min-width: 768px){
    .col-md-1-5 {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .col-md-2-5 {
      -ms-flex: 0 0 20.833%!important;
      flex: 0 0 20.833%!important;
      max-width: 20.833%!important;
    }
    .col-md-4-5 {
      -ms-flex: 0 0 37.5%!important;
      flex: 0 0 37.5%!important;
      max-width: 37.5%!important;
    }
    .col-md-3-5 {
      -ms-flex: 0 0 29.166%!important;
      flex: 0 0 29.166%!important;
      max-width: 29.166%!important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .solid-background {
    opacity: 1 !important;
  }
`;

export default GlobalStyle;
