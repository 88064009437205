/**
 *
 * ConfirmationDialog
 *
 */

import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'reactstrap/es/Modal';
import { ModalHeaders, ModalBodys } from 'components/Modal';
import ButtonAsync from '../ButtonAsync';

const ConfirmModal = styled(Modal)`
  @media (min-width: 576px){
    &.modal-dialog{
      margin-top: 200px;
      max-width: 400px;
    }
  }
`;

function ConfirmationDialog({
  title,
  message,
  rejectText,
  confirmText,
  onClose,
  onConfirm,
}) {
  const onClickOk = () => {
    onConfirm();
    onClose();
  };
  return (
    <ConfirmModal isOpen={true}>
      <ModalHeaders title={title} toggle={()=>onClose()} />
      <ModalBodys>
        <p>{message}</p>
        <div className="text-right">
          <ButtonAsync onClick={onClose} color="light" className="mr-2">{rejectText || 'No'}</ButtonAsync>
          <ButtonAsync onClick={onClickOk} color="primary">{confirmText || 'Yes'}</ButtonAsync>
        </div>
      </ModalBodys>
    </ConfirmModal>
  );
}

ConfirmationDialog.propTypes = {};

export default memo(ConfirmationDialog);
