import React, { Children } from 'react';
import styled from 'styled-components';
import { ModalFooter, ModalHeader, ModalBody } from 'reactstrap';

const StyledFooter = styled(ModalFooter)`
  padding: 0 25px 25px;
`;

function ModalFooters(props) {
  return (
    <StyledFooter {...props}>
      {Children.toArray(props.children)}
    </StyledFooter>
  );
}

export default ModalFooters;

