import React from 'react';
import styled from 'styled-components';
import Button from 'reactstrap/es/Button';
import CloseButton from './includes/CloseButton';
import { ModalFooter, ModalHeader, ModalBody } from 'reactstrap';

const StyledHeader = styled(ModalHeader)`
  margin: -1px 0 0;
  padding: 10px 25px;
  background: #2a86cf !important;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 1px solid #E8E8E8;
  .close-button{
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    text-align: center;
    right: 10px;
    top: 7px;
    padding: 0;
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    box-shadow: none;
    border: none;
    &:focus, &:active{
      background-color: transparent;
      outline: none;
    }
  }
  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }
`;
const HeaderTitle = styled.span`
  font-weight: 600;
  color: ${props => props.theme.whiteColor};
  margin: 0;
  font-size: 14px;
`;

function ModalHeaders(props) {
  return (
    <StyledHeader>
      <HeaderTitle>{props.title}</HeaderTitle>
      {!props.disableClose &&
      (<Button className="close-button" onClick={props.toggle}>
        <CloseButton/>
      </Button>)
      }
    </StyledHeader>
  );
}

export default ModalHeaders;
