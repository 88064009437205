import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
// import md5 from 'md5';
import { Utils } from 'utils';
import * as AccessTokenInterceptor from './interceptors/accessToken';
import * as UnauthorizeInterceptor from './interceptors/unauthorize';
import * as LanguageInterceptor from './interceptors/acceptLanguage';
import { AppConfig, Env, Endpoints } from '../constants';
import _ from 'lodash';

export const getInstance = () => {
  const instance = axios.create({
    baseURL: AppConfig.API_BASE_URL,
    timeout: 30000,
  });

  instance.interceptors.response.use(UnauthorizeInterceptor.onFullfilled, UnauthorizeInterceptor.onRejected);

  // instance.interceptors.request.use(
  //   LogInterceptor.requestLog,
  //   LogInterceptor.requestError,
  // );

  // instance.interceptors.response.use(
  //   LogInterceptor.responseLog,
  //   LogInterceptor.responseError,
  // );

  instance.interceptors.request.use(AccessTokenInterceptor.addAccessToken, AccessTokenInterceptor.onRejected);
  instance.interceptors.request.use(LanguageInterceptor.addLanguage);
  return instance;
};

const API = {
  instance: getInstance(),
};

const parameterEncoding = (url, params) => {
  url += '?';
  var queryString = Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
  }).join('&');
  return url + queryString;
};

/**
 * Auth API
 */
API.getDeviceId = () =>
  new Promise(resolve => {
    new Fingerprint2().get(result => {
      resolve(result);
    });
  });

API.login = loginInfo => {
  const data = {
    username: loginInfo.username,
    password: loginInfo.password,
    platform: AppConfig.platformType,
    env: Env.dev,
  };

  return API.instance
    .post(Endpoints.LOGIN_URL, data)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};


API.ping = () => {
  return API.instance
    .get(Endpoints.PING_URL)
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

API.loginFacebook = () => {
  return API.instance
    .get(Endpoints.LOGIN_FACEBOOK)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

API.logout = () => {
  return API.instance
    .post(Endpoints.LOGOUT_URL)
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

API.activateAccount = postData => {
  return API.instance
    .post(Endpoints.ACTIVATE_ACCOUNT, postData)
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

API.changePassword = postData => {
  return API.instance
    .put(Endpoints.CHANGE_PASSWORD, postData)
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

API.profileDetails = () => {
  return API.instance
    .get(Endpoints.USER_PROFILE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.editUserProfile = (formData) => {
  return API.instance
    .put(Endpoints.USER_PROFILE, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.changeStaffPassword = (formData, id) => {
  return API.instance
    .put(`${Endpoints.STAFF_PWD_URL}/${id}`, formData)
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

API.resetPassword = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.RESET_PASSWORD, params))
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

API.getS3Credential = () => {
  return API.instance
    .get(Endpoints.S3_CREDENTIAL)
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

API.searchContact = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SEARCH_CONTACT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.searchOpportunity = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.OPPORTUNITY_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createOpportunity = formData => {
  return API.instance
    .post(Endpoints.OPPORTUNITY_URL, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.editOpportunity = (formData, id) => {
  return API.instance
    .put(`${Endpoints.OPPORTUNITY_URL}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteOpportunity = params => {
  let listID = _.map(params.ids, param => {
    return param;
  })
  return API.instance
    .delete(`${Endpoints.OPPORTUNITY_CANCEL}?ids=${listID.join(',')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.searchCustomContact = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_CUSTOM_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.searchCustomOrder = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.ORDER_CUSTOM_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.searchCustomOpportunity = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.OPPORTUNITY_CUSTOM_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getProductCategories = () => {
  return API.instance
    .get(Endpoints.PRODUCT_CATEGORIES)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.getProductSimple = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PRODUCT_SIMPLE_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getCustomProduct = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PRODUCT_CUSTOM_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.getProductReport = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.REPORT_PRODUCT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.getContactReport = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.REPORT_CONTACT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.getContactDebtsById = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_DEBT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.getProductSaleReport = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.REPORT_PRODUCT_SALE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.updateProductSkuPrice = formData => {
  return API.instance
    .put(Endpoints.PRODUCT_SKU_PRICE_UPDATE, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.getContactAssignee = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_ASSIGNEE_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.setContactAssignee = params => {
  return API.instance
    .put(Endpoints.CONTACT_ASSIGN, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactVisibility = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_VISIBILITY_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getMonthlyReport = () => {
  return API.instance
    .get(Endpoints.REPORT_MONTHLY_HIGHLIGHT)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactReportBySources = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.REPORT_CONTACT_BY_SOURCE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getRevenueReport = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.REPORT_REVENUE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getVendor = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SUPPLIER_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getProductSku = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PRODUCT_SKU_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getProductSkuByProductId = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PRODUCT_UPDATE_SKU, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getProductDetails = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PRODUCT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateProductDetails = (params, id) => {
  return API.instance
    .put(`${Endpoints.PRODUCT}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactStatusCount = () => {
  return API.instance
    .get(Endpoints.GET_CONTACT_STATUS)
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

API.getContactSequence = async () => {
  return API.instance
    .get(Endpoints.CONTACT_SEQUENCE_LOAD)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.searchInventory = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.INVENTORY_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.searchInventoryNotes = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.INVENTORY_IMPORT_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getInventoryNoteDetails = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.INVENTORY_IMPORT_DETAIL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.editInventoryNote = (formData, id) => {
  return API.instance
    .put(`${Endpoints.INVENTORY_IMPORT}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getInventoryNoteSequence = () => {
  return API.instance
    .get(Endpoints.INVENTORY_IMPORT_SEQUENCE_NEXT)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateInventory = (params, id) => {
  return API.instance
    .put(`${Endpoints.INVENTORY_URL}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getInventoryDetails = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.INVENTORY_URL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addNewContact = (params) => {
  return API.instance
    .post(Endpoints.CONTACT, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addNewInventory = (params) => {
  return API.instance
    .post(Endpoints.INVENTORY_URL, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createCoupon = (params) => {
  return API.instance
    .post(Endpoints.INVENTORY_IMPORT, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addContactAttachment = (params) => {
  return API.instance
    .post(Endpoints.CONTACT_ATTACHMENT, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addNewJob = (params) => {
  return API.instance
    .post(Endpoints.JOB_TASK, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addNewVendor = (params) => {
  return API.instance
    .post(Endpoints.SUPPLIER, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getVendorType = (params) => {
  return API.instance
    .get(Endpoints.SUPPLIER_TYPE_SEARCH, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}



API.addNewStaff = (params) => {
  return API.instance
    .post(Endpoints.STAFF, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}
API.searchStaff = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SEARCH_STAFF, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getStaffGroup = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.STAFF_GROUP, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addNewStaffGroup = (params) => {
  return API.instance
    .post(Endpoints.STAFF_GROUP, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactDetail = (params) => {
  return API.instance
    .get(`${Endpoints.CONTACT}?id=${params}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactHighlight = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_HIGHLIGHT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.getStaffDetail = (params) => {
  return API.instance
    .get(`${Endpoints.STAFF}?id=${params}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.userSearch = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(`${Endpoints.USER_SEARCH}`, params))
    .then(response => response.data).
    catch(error => {
      throw error;
    });
}

API.roleSearch = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(`${Endpoints.ROLE_SEARCH}`, params))
    .then(response => response.data).
    catch(error => {
      throw error;
    });
}

API.userRoleSearch = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(`${Endpoints.USER_ROLE_SEARCH}`, params))
    .then(response => response.data).
    catch(error => {
      throw error;
    });
}

API.getVendorDetails = (params) => {
  return API.instance
    .get(`${Endpoints.SUPPLIER}?supplierId=${params}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateVendor = (params, id) => {
  return API.instance
    .put(`${Endpoints.SUPPLIER}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.countContactEvent = (params) => {
  return API.instance
    .get(`${Endpoints.CONTACT_COUNT_EVENT}?id=${params}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.countContactTask = (params) => {
  return API.instance
    .get(`${Endpoints.CONTACT_COUNT_TASK}?id=${params}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.contactTaskSearch = (params) => {
  return API.instance
    .get(`${Endpoints.TASK_SEARCH}?contactId=${params}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getTaskSearch = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.TASK_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getStaffListByProject = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.TASK_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getTaskGroupSearch = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.TASK_GROUP_SEARCH,params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getTaskDetails = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.TASK_DETAIL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.editTask = (formData, id) => {
  return API.instance
    .put(`${Endpoints.TASK_UPDATE}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.contactCommentSearch = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_COMMENT_LIST, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.sendComment = (params) => {
  return API.instance
    .post(Endpoints.CONTACT_COMMENT, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.contactAddTask = (params) => {
  return API.instance
    .put(Endpoints.CONTACT_TAG, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.contactUpdateProfile = (params, id) => {
  return API.instance
    .put(`${Endpoints.CONTACT}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.staffUpdateProfile = (params, id) => {
  return API.instance
    .put(`${Endpoints.STAFF}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.staffGroupUpdate = (params, id) => {
  return API.instance
    .put(`${Endpoints.STAFF_GROUP}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.contactGroupUpdate = (params) => {
  return API.instance
    .put(Endpoints.CONTACT_GROUP_UPDATE, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addAdvanFilter = (params) => {
  return API.instance
    .post(Endpoints.CONTACT_FILTER, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getAdvanFilter = () => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_FILTER))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactExport = () => {
  return API.instance
    .get(`${Endpoints.CONTACT_EXPORT}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPublicExport = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PUBLIC_EXPORT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactStatus = () => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_STATUS))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactGroup = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_GROUP, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addContactGroup = (params) => {
  return API.instance
    .post(Endpoints.CONTACT_GROUP, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactDocument = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_ATTACHMENT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactActivity = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_ACTIVITY, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    }
    );
}


API.getCustomerName = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SEARCH_CONTACT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactCustom = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_CUSTOM, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getStaffName = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SEARCH_STAFF, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getTypeJob = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SEARCH_TYPE_JOB, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPriorityJob = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SEARCH_PRIORITY_JOB, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOpportunityJob = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.SEARCH_OPPORTUNITY_JOB, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getFeature = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.GET_FEATURE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getFeatureGroup = () => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.GET_FEATURE_GROUP))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getRolePermission = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.ROLE_PERMISSION, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateRolePermission = (params) => {
  return API.instance
    .put(Endpoints.ROLE_PERMISSION, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteRowsTable = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.CONTACT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteRowsOrderTable = (params) => {
  let listID = _.map(params.ids, param => {
    return param;
  })
  return API.instance
    .delete(`${Endpoints.ORDER_CANCEL}?ids=${listID.join(',')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteRowProductSimple = (params) => {
  const listID = params.map(r => { if (r.id != _.last(params).id) { return `ids=${r.id}&` } else { return `ids=${r.id}` } })
  return API.instance
    .delete(`${Endpoints.PRODUCT}?${listID.join('')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteRowsInventory = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.INVENTORY_URL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteRowProductSku = (params) => {
  const listID = params.map(r => {
    if (r.productSku.id && r.productSku.id != _.last(params).productSku.id) { return `ids=${r.productSku.id}&` } else { return `ids=${r.productSku.id}` }
  })
  return API.instance
    .delete(`${Endpoints.PRODUCT_UPDATE_SKU}?${listID.join('')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteRowsTask = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.TASK_DELETE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteAdvanFilter = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.CONTACT_FILTER, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.contactDeleteGroup = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.CONTACT_DELETE_GROUP, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteProduct = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.PRODUCT_SIMPLE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.contactDeleteTag = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.CONTACT_DELETE_TAG, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.addUserIntRole = (params) => {
  return API.instance
    .put(Endpoints.USER_ROLE, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteUserRole = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.USER_ROLE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.deleteStaff = (params) => {
  const listID = params.map(r => { if (r.id != _.last(params).id) { return `ids=${r.id}&` } else { return `ids=${r.id}` } })
  return API.instance
    .delete(`${Endpoints.STAFF}?${listID.join('')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addAtachment = params => {
  return API.instance
    .post(Endpoints.ATTACHMENT, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addTypeJob = params => {
  return API.instance
    .post(Endpoints.TYPE, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.uploadDataFile = (file, endpoint) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return API.instance
    .post(endpoint, formData, config)
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

  API.addNewProduct = (params) => {
    return API.instance
      .post(Endpoints.PRODUCT_SIMPLE, params)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

API.addNewRole = (params) => {
  return API.instance
    .post(Endpoints.ROLE, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateProductSku = (params, id) => {
  return API.instance
    .put(`${Endpoints.PRODUCT_UPDATE_SKU}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateUserPermission = (params) => {
  return API.instance
    .put(Endpoints.USER_PERMISSION, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getUserPermission = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.USER_PERMISSION, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOrderList = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.ORDER_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOrderExport = () => {
  return API.instance
    .get(`${Endpoints.ORDER_EXPORT}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOrderPreview = (params) => {
  return API.instance
    .get(`${Endpoints.ORDER_PREVIEW}?id=${params.id}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getExportProgress = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.EXPORT_PROGRESS, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOrderContact = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_ORDER, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactOpportunity = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_OPPORTUNITY, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createOrder = (formData) => {
  return API.instance
    .post(Endpoints.ORDER_CREATE, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOrderDetail = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.ORDER_DETAIL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.editOrder = (formData, id) => {
  return API.instance
    .put(`${Endpoints.ORDER_DETAIL}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.approveOrder = (id, formData) => {
  return API.instance
    .put(`${Endpoints.ORDER_APPROVE}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.approveInventoryNote = (params) => {
  return API.instance
    .put(`${Endpoints.INVENTORY_APPROVE}/${params}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.cancelOrder = (params) => {
  let listID = _.map(params.ids, param => {
    return param;
  });
  return API.instance
    .delete(`${Endpoints.ORDER_CANCEL}?ids=${listID.join(',')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.cancelInventoryNote = (params) => {
  let listID = _.map(params.ids, param => {
    return param;
  });
  return API.instance
    .delete(`${Endpoints.INVENTORY_CANCEL}/?ids=${listID.join(',')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOrderSequence = () => {
  return API.instance
    .get(Endpoints.ORDER_SEQUENCE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOpportunitySequence = () => {
  return API.instance
    .get(Endpoints.OPPORTUNITY_SEQUENCE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOpportunityDetail = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.OPPORTUNITY_URL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getOpportunityExport = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.OPPORTUNITY_EXPORT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getProductExport = () => {
  return API.instance
    .get(`${Endpoints.PRODUCT_EXPORT}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getProductSequence = () => {
  return API.instance
    .get(Endpoints.PRODUCT_SEQUENCE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createSimpleProduct = (formData) => {
  return API.instance
    .post(Endpoints.ADD_SIMPLE_PRODUCT, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getCompanyList = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.COMPANY_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addNewCompany = (params) => {
  return API.instance
    .post(Endpoints.COMPANY_CREATE, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateCompany = (params) => {
  return API.instance
    .put(Utils.buildUrlWithParams(Endpoints.COMPANY_UPDATE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getCompanyDetail = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.COMPANY_UPDATE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.companyDataUpdate = (params, id) => {
  return API.instance
    .put(`${Endpoints.COMPANY_UPDATE}/${id}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteCompany = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.COMPANY, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getTaskDetail = async (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.TASK_DETAIL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getStockReport = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.REPORT_STOCK, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getUserConfigs = async () => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.GET_APPLICATION_CONFIG, {}))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getListUnitCompute = (params) => {
  return API.instance
    .get(`${Endpoints.GET_APPLICATION_CONFIG_DETAILS}?key=${params.key}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createUnitCompute = (formData, key) => {
  return API.instance
    .put(`${Endpoints.GET_APPLICATION_CONFIG}/${key}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getCustomComplex = () => {
  return API.instance
    .get(Endpoints.GET_CUSTOM_COMPLEX)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.searchCampaign = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CAMPAIGN_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createCampaign = (formData) => {
  return API.instance
    .post(Endpoints.CAMPAIGN_URL, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getCampaignDetails = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CAMPAIGN_URL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.updateCampaign = (formData, id) => {
  return API.instance
    .put(`${Endpoints.CAMPAIGN_URL}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.cancelCampaign = (params) => {
  let listID = _.map(params.ids, param => {
    return param;
  });
  return API.instance
    .delete(`${Endpoints.CAMPAIGN_URL}?ids=${listID.join(',')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addContactToTheCampaign = (params) => {
  return API.instance
    .put(Endpoints.CAMPAIGN_ASSIGN, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.searchEmailTemplates = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.EMAIL_TEMPLATE_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createEmailTemplate = (formData) => {
  return API.instance
    .post(Endpoints.EMAIL_TEMPLATE_URL, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.editEmailTemplate = (formData, id) => {
  return API.instance
    .put(`${Endpoints.EMAIL_TEMPLATE_URL}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getEmailTemplateDetails = (id) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.EMAIL_TEMPLATE_URL, id))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteEmailTemplates = (params) => {
  let listID = _.map(params.ids, param => param);
  return API.instance
    .delete(`${Endpoints.CAMPAIGN_URL}?ids=${listID.toString()}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createBankAccount = (formData) => {
  return API.instance
    .post(Endpoints.BANK_ACCOUNT_CREATE, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createProject = (formData) => {
  return API.instance
    .post(Endpoints.PROJECT_CREATE, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getBankAccount = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.BANK_ACCOUNT_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getBankAccountDetails = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.BANK_ACCOUNT_DETAIL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getProject = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PROJECT_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getProjectDetails = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PROJECT_DETAILS, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateProject = (formData, id) => {
  return API.instance
    .put(`${Endpoints.PROJECT_DETAILS}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.cancelProject = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.PROJECT_DELETE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.assignMember = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PROJECT_ASSIGN, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getContactDebts = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_DEBTS, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getContactDebtTransaction = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CONTACT_DEBTS_TRANSACTION, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.editBankAccount = (formData, id) => {
  return API.instance
    .put(`${Endpoints.BANK_ACCOUNT_URL}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.cancelBankAccount = (params) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.BANK_ACCOUNT_DELETE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createReceipt = (formData) => {
  return API.instance
    .post(Endpoints.RECEIPT, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getReceiptSequence = () => {
  return API.instance
    .get(Endpoints.RECEIPT_SEQUENCE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createPaymentBill = (formData) => {
  return API.instance
    .post(Endpoints.PAYMENT_BILL, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPaymentBillSequence = () => {
  return API.instance
    .get(Endpoints.PAYMENT_BILL_SEQUENCE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPaymentList = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.PAYMENT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.getTransferSequence = () => {
  return API.instance
    .get(Endpoints.TRANSFER_SEQUENCE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createTransfer = formData => {
  return API.instance
    .post(Endpoints.TRANSFER, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPOSSequence = () => {
  return API.instance
    .get(Endpoints.POS_SEQUENCE)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createPOS = formData => {
  return API.instance
    .post(Endpoints.POS_URL, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPOSList = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createPosSession = params => {
  return API.instance
    .post(Endpoints.POS_SESSION_URL, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.getSessionSequence = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_SESSION_SEQUENCE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.closePosSession = (id) => {
  return API.instance
    .put(`${Endpoints.POS_CLOSE_SESSION}/${id}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.simpleSearchPOS = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_SIMPLE_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.editPOS = (formData, id) => {
  return API.instance
    .put(`${Endpoints.POS_URL}/id`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.POSAssign = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_ASSIGN, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deletePOS = params => {
  let listID = _.map(params.ids, param => param);
  return API.instance
    .delete(`${Endpoints.POS_URL}?ids=${listID.join(',')}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPOSSessionOrders = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_SESSION_SEARCH_ORDER, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPOSSessionOrdersById = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_SESSION_ORDER, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getPosSession = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_SESSION_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createPOSSesssionOrder = formData => {
  return API.instance
    .post(Endpoints.POS_SESSION_ORDER_URL, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.cancelPOSSessionOrder = id => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.POS_SESSION_ORDER_URL, id))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}


API.getPOSSessionOrderDetail = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_SESSION_ORDER_URL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updatePOSSessionOrderContact = (formData, id) => {
  return API.instance
    .put(`${Endpoints.POS_SESSION_UPDATE_CONTACT}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.approvedSession = params => {
  return API.instance
    .post(Endpoints.POS_SESSION_CONFIRM, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updatePOSSessionOrderDiscount = (formData, id) => {
  return API.instance
    .put(`${Endpoints.POS_SESSION_UPDATE_DISCOUNT}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updatePOSSessionOrderTax = (formData, id) => {
  return API.instance
    .put(`${Endpoints.POS_SESSION_UPDATE_TAX}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addProductToPOSSessionOrder = formData => {
  return API.instance
    .post(Endpoints.POS_SESSION_ORDER_DETAILS, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.removeProductFromPOSSessionOrder = id => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.POS_SESSION_ORDER_DETAILS, id))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updatePOSSessionProductSku = (formData, id) => {
  return API.instance
    .put(`${Endpoints.POS_SESSION_UPDATE_PRODUCT_SKU}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updatePOSSessionProductQuantity = (formData, id) => {
  return API.instance
    .put(`${Endpoints.POS_SESSION_UPDATE_PRODUCT_QUANTITY}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createPOSSessionPayment = formData => {
  return API.instance
    .post(Endpoints.POS_PAYMENT, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getAllCategoryPreset = () => {
  return API.instance
    .get((Endpoints.CATEGORY_PRESET))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.createCategoryPreset = (params) => {
  return API.instance
    .post(Endpoints.CREATE_CATEGORY_PRESET, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.searchProductCategories = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.POS_PRODUCT_CATEGORY_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.forgotPassword = (params) => {
  return API.instance
    .get(`${Endpoints.FORGOT_PASSWORD}?email=${params.email}`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}


// .get(`${Endpoints.PASSWORD_RESET}?code=${params.code}&pwd=${params.pwd}`)
API.resetPassword = (params) => {
  return API.instance
    .get(parameterEncoding(Endpoints.PASSWORD_RESET, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.getListCustomFields = params => {
  return API.instance
    .get(`${Endpoints.CUSTOMFIELD}/${params.entity}/search`, params)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.createCustomField = formData => {
  return API.instance
    .post(`${Endpoints.CUSTOMFIELD}/${formData.entity}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getCustomFieldDetails = id => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.CUSTOMFIELD_DETAIL, id))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateCustomField = (formData, id) => {
  return API.instance
    .put(`${Endpoints.CUSTOMFIELD}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.deleteCustomFields = ids => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.CUSTOMFIELD_DETAIL, ids))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getMailReport = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.MAIL_REPORT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getResourcesList = (params) => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.RESOURCE, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.createResources = (formData) => {
  return API.instance
    .post(Endpoints.RESOURCE_CREATE, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getResourcesDetails = id => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.RESOURCE_DETAIL, id))
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.updateResource = (formData, id) => {
  return API.instance
    .put(`${Endpoints.RESOURCE_DETAIL}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    })
}

API.deleteResource = (ids) => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.RESOURCE_DETAIL, ids))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getResourceOrders = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.RESOURCE_ORDER_SEARCH, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getResourceOrderDetails = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.RESOURCE_ORDER, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createResourceOrder = formData => {
  return API.instance
    .post(Endpoints.RESOURCE_ORDER, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateResourceOrderContact = (formData, id) => {
  return API.instance
    .put(`${Endpoints.RESOURCE_ORDER_CONTACT}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateResourceOrderDiscount = (formData, id) => {
  return API.instance
    .put(`${Endpoints.RESOURCE_ORDER_DISCOUNT}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateResourceOrderTax = (formData, id) => {
  return API.instance
    .put(`${Endpoints.RESOURCE_ORDER_TAX}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.addProductToResourceOrder = formData => {
  return API.instance
    .post(Endpoints.RESOURCE_ORDER_DETAIL, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.removeProductFromResourceOrder = params => {
  return API.instance
    .delete(Utils.buildUrlWithParams(Endpoints.RESOURCE_ORDER_DETAIL, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateResourceOrderDetailsQuantity = (formData, id) => {
  return API.instance
    .put(`${Endpoints.RESOURCE_ORDER_DETAIL_QUANTITY}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.updateResourceOrderDetailsProductSku = (formData, id) => {
  return API.instance
    .put(`${Endpoints.RESOURCE_ORDER_DETAIL_SKU}/${id}`, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.createNewTaskGroup = formData => {
  return API.instance
    .post(Endpoints.TASK_GROUP, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.getTaskGroupByProjectId = params => {
  return API.instance
    .get(Utils.buildUrlWithParams(Endpoints.TASK_GROUP_PROJECT, params))
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

API.changeTaskPosition = formData => {
  return API.instance
    .put(Endpoints.TASK_GROUP_POSITION, formData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

API.createAddSkuGenerate = formData =>{
  return API.instance
  .post(Endpoints.PRODUCT_GENERATE_SKU, formData)
  .then(response => response.data)
  .catch(error => {
    throw error;
  });
};
export default API;

