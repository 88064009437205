export default {
  loginSuccess: 'You have been logged in successfully',
  loginRequired: 'You need to log in to continue.',
  logoutSuccess: 'You have been logged out',

  confirmTitle: 'Confirm',
  confirmLogout: 'Are you sure you want to logout?',

  changePasswordSuccess: 'Your password has been changed successfully',

  requiredField: 'This field is required.',
  integerField: 'Only integer allowed',
  passwordMustMatch: 'Passwords must be match',

  addNewContactSuccess: 'New contact added',
  updateNewContactSuccess: 'Contact has been updated',
  addNewJobSuccess: 'New job added',
  addNewVendorSuccess: 'New job added',
  addNewStaffSuccess: 'New staff added',
  updateStaffSuccess: 'Staff have been updated',
  addNewStaffGroupSuccess: 'New staff group added',
  deletedRowsInTable: `You have deleted {0}`,
  staffDeletedMessage: 'Staff have been deleted',
  deleteAdvanFilter: 'You have been deleted advance filter',

  addNewProductSuccess: 'New product added',
  deleteProductSuccess: 'You have been deleted product',

  createOrderSuccess: 'New order has been created successfully',
  confirmDeleteOrder: 'Are you sure?',
  deleteOrderSuccess: 'Order has been deleted successfully',
  
  sendCommentSuccess: 'You have been sent comment successfully',
  updateProductDetailsSuccess: 'Product have been updated',
  updateVendorSuccess: 'Vendor has been updated',

  deleteUserRoleSuccess: 'You have been deleted a user',

  createInventorySuccess: 'You have created an inventory successfully',
  updateInventorySuccess: 'Inventory has been updated successfully',

  createCompanySuccess: 'You have created an company successfully',
  updateCompanySuccess: 'Company has been updated successfully',
  companyDeletedMessage: 'Company have been deleted',

  createOpportunitySuccess: 'You have created an opportunity successfully',

};
