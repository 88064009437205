import React, { Children } from 'react';
import styled from 'styled-components';
import { ModalFooter, ModalHeader, ModalBody } from 'reactstrap';

const StyledBody = styled(ModalBody)`
  padding: 25px;
`;

function ModalBodys(props) {
  return (
    <StyledBody {...props}>
      {Children.toArray(props.children)}
    </StyledBody>
  );
}

export default ModalBodys;