/*
 * App Messages
 *
 * This contains all the text for the ContactPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  promptNav: {
  	id: `${scope}.promptNav`,
  	defaultMessage: 'You have unsaved data. Changes you made will be lost',
  },
  requiredFieldDefaultMessage: {
    id: `${scope}.requiredFieldDefaultMessage`,
    defaultMessage: 'This field is required',
  },
  confirmText: {
    id: `${scope}.confirmText`,
    defaultMessage: 'Confirmation',
  },
  acceptText: {
    id: `${scope}.acceptText`,
    defaultMessage: 'Yes',
  },
  rejectText: {
    id: `${scope}.rejectText`,
    defaultMessage: 'No',
  },
  loginSuccess: {
    id: `${scope}.loginSuccess`,
    defaultMessage: 'You have been logged in successfully',
  },
  loginRequired: {
    id: `${scope}.loginRequired`,
    defaultMessage: 'You need to log in to continue.',
  },
  serverError: {
    id: `${scope}.serverError`,
    defaultMessage: 'Internal server error. Please try again in a few minutes.',
  },
  logoutSuccess: {
    id: `${scope}.logoutSuccess`,
    defaultMessage: 'You have been logged out.',
  },
  confirmTitle: {
    id: `${scope}.confirmTitle`,
    defaultMessage: 'Confirm',
  },
  confirmLogout: {
    id: `${scope}.confirmLogout`,
    defaultMessage: 'Are you sure you want to logout?',
  },
  changePasswordSuccess: {
    id: `${scope}.changePasswordSuccess`,
    defaultMessage: 'Your password has been changed successfully',
  },
  integerField: {
    id: `${scope}.integerField`,
    defaultMessage: 'Only integer allowed',
  },
  passwordMustMatch: {
    id: `${scope}.passwordMustMatch`,
    defaultMessage: 'Passwords must be match',
  },
  addNewContactSuccess: {
    id: `${scope}.addNewContactSuccess`,
    defaultMessage: 'New contact added',
  },
  updateNewContactSuccess: {
    id: `${scope}.updateNewContactSuccess`,
    defaultMessage: 'Contact has been updated',
  },
  addNewJobSuccess: {
    id: `${scope}.addNewJobSuccess`,
    defaultMessage: 'New job added',
  },
  addNewVendorSuccess: {
    id: `${scope}.addNewVendorSuccess`,
    defaultMessage: 'New job added',
  },
  deletedRowsInTable: {
    id: `${scope}.deletedRowsInTable`,
    defaultMessage: 'You have deleted {0}',
  },
  staffDeletedMessage: {
    id: `${scope}.staffDeletedMessage`,
    defaultMessage: 'Staff have been deleted',
  },
  deleteAdvanFilter: {
    id: `${scope}.deleteAdvanFilter`,
    defaultMessage: 'You have been deleted advance filter',
  },
  addNewProductSuccess: {
    id: `${scope}.addNewProductSuccess`,
    defaultMessage: 'New product added',
  },
  deleteProductSuccess: {
    id: `${scope}.deleteProductSuccess`,
    defaultMessage: 'You have been deleted product',
  },
  createOrderSuccess: {
      id: `${scope}.createOrderSuccess`,
      defaultMessage: 'New order has been created successfully',
  },
  confirmDeleteOrder: {
      id: `${scope}.confirmDeleteOrder`,
      defaultMessage: 'Are you sure?',
  },
  sendCommentSuccess: {
      id: `${scope}.sendCommentSuccess`,
      defaultMessage: 'You have been sent comment successfully',
  },
  updateProductDetailsSuccess: {
      id: `${scope}.updateProductDetailsSuccess`,
      defaultMessage: 'Product have been updated',
  },
  updateVendorSuccess: {
      id: `${scope}.updateVendorSuccess`,
      defaultMessage: 'Vendor has been updated',
  },
  deleteUserRoleSuccess: {
      id: `${scope}.deleteUserRoleSuccess`,
      defaultMessage: 'You have been deleted a user',
  },
  createInventorySuccess: {
      id: `${scope}.createInventorySuccess`,
      defaultMessage: 'You have created an inventory successfully',
  },
  updateInventorySuccess: {
      id: `${scope}.updateInventorySuccess`,
      defaultMessage: 'Inventory has been updated successfully',
  },
  createCompanySuccess: {
      id: `${scope}.createCompanySuccess`,
      defaultMessage: 'You have created an company successfully',
  },
  updateCompanySuccess: {
      id: `${scope}.updateCompanySuccess`,
      defaultMessage: 'Company has been updated successfully',
  },
  companyDeletedMessage: {
      id: `${scope}.companyDeletedMessage`,
      defaultMessage: 'Company have been deleted',
  },
  createOpportunitySuccess: {
      id: `${scope}.createOpportunitySuccess`,
      defaultMessage: 'You have created an opportunity successfully',
  },
  createCouponSuccess: {
      id: `${scope}.createCouponSuccess`,
      defaultMessage: 'You have created a products import coupon successfully',
  },
  deleteOpportunitySuccess: {
    id: `${scope}.deleteOpportunitySuccess`,
    defaultMessage: 'You have deleted {0} opportunity(s).',
  },
  deleteOrdersSuccess: {
    id: `${scope}.deleteOrdersSuccess`,
    defaultMessage: 'You have deleted {0} order(s).',
  },
  deleteCampaignsSuccess: {
    id: `${scope}.deleteCampaignsSuccess`,
    defaultMessage: 'You have deleted {0} campaign(s).',
  },
  editOrdersSuccess: {
    id: `${scope}.editOrdersSuccess`,
    defaultMessage: 'You have successfully edited an order!',
  },
  editInventoryNoteSuccess: {
    id: `${scope}.editInventoryNoteSuccess`,
    defaultMessage: 'You have successfully edited this inventory note!',
  },
  createCampaignSuccess: {
    id: `${scope}.createCampaignSuccess`,
    defaultMessage: 'You have successfully created a campaign!',
  },
  editCampaignSuccess: {
    id: `${scope}.editCampaignSuccess`,
    defaultMessage: 'You have successfully edited a campaign!',
  },
  editEmailTemplateSuccess: {
    id: `${scope}.editEmailTemplateSuccess`,
    defaultMessage: 'You have successfully edited an email template!',
  },
  createEmailTemplateSuccess: {
    id: `${scope}.createEmailTemplateSuccess`,
    defaultMessage: 'You have successfully created an email template!',
  },
  deleteEmailTemplateSuccess: {
    id: `${scope}.deleteEmailTemplateSuccess`,
    defaultMessage: 'You have deleted {0} email template(s)!',
  },
  createBankAccountSuccess: {
    id: `${scope}.createBankAccountSuccess`,
    defaultMessage: 'You have successfully created Bank Account!',
  },
  editBankAccountSuccess: {
    id: `${scope}.editBankAccountSuccess`,
    defaultMessage: 'You have successfully edited a Bank Account!',
  },
  createProjectSuccess: {
    id: `${scope}.createProjectSuccess`,
    defaultMessage: 'You have successfully created a Project',
  },
  EditProjectSuccess: {
    id: `${scope}.EditProjectSuccess`,
    defaultMessage: 'You have successfully Edit a Project',
  },
  editTaskSuccess: {
    id: `${scope}.editTaskSuccess`,
    defaultMessage: 'You have successfully edited a Task',
  },
  addAssigneeSuccess: {
    id: `${scope}.addAssigneeSuccess`,
    defaultMessage: 'You have successfully added assignee(s) to this contact!',
  },
  dateRangePickerPlaceholder: {
    id: `${scope}.dateRangePickerPlaceholder`,
    defaultMessage: 'From - To',
  },
  notification: {
    id: `${scope}.notification`,
    defaultMessage: 'Notification',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  approvedSessionSuccess: {
    id: `${scope}.approvedSessionSuccess`,
    defaultMessage: 'You have successfully approved a Session',
  },
  createPosSuccess: {
    id: `${scope}.createPosSuccess`,
    defaultMessage: 'You have successfully created a Pos',
  },
  comfirmForgotPasswordSuccess: {
    id: `${scope}.comfirmForgotPasswordSuccess`,
    defaultMessage: 'Password Reset Request Successfully',
  },
  resetPasswordSuccess: {
    id: `${scope}.resetPasswordSuccess`,
    defaultMessage: 'Password Reset Request Successfully',
  },
  createResourcesSuccess: {
    id: `${scope}.createResourcesSuccess`,
    defaultMessage: 'You have successfully created Resources!',
  },
  editResourcesSuccess: {
    id: `${scope}.editResourcesSuccess`,
    defaultMessage: 'You have successfully edited Resources!',
  },
  createUnitSuccess: {
    id: `${scope}.createUnitSuccess`,
    defaultMessage: 'You have successfully created Unit',
  },
  editUnitSuccess: {
    id: `${scope}.editUnitSuccess`,
    defaultMessage: 'You have successfully edited Unit',
  },
  deleteUnitSuccess: {
    id: `${scope}.deleteUnitSuccess`,
    defaultMessage: 'You have successfully delete Unit',
  },
});