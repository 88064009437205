import styled from 'styled-components';

const StyledFixedMenu = styled.div`
	.react-draggable {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
	.fixed-menu {
		position: fixed;
	  bottom: 70px;
	  right: 0;
	  max-height: 300px;
	  z-index: 9;
	  min-width: 520px;
	  @media (max-width: 767px) {
	    min-width: 300px;
	  }
	  .menu {    
      transition: all 0.5s ease;
      line-height: 20px;
      text-align: center;
	    &.hide {
	      display: none !important;
	    }
	    &.show {
	      display: block !important;
	    }
	  }
	  .display-table {
      margin-left: 200px;
	    display: table;
	    .display-tablecell {
	      display: table-cell;
	      .ml-20 {
	        margin-left: 5px;
	      }
	    }
	  }
	}
`;

export default StyledFixedMenu;