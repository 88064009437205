import { css } from 'styled-components';

const buttonWelcomeStyles = css`
  &.btn-welcome , &.chat {
    padding: 7px 20px;
    border-radius: 30px;
    background-color: ${props => props.theme.btnAccountInfoColor};
    border: 2px solid;
    &:focus {
      outline: none;
    }
    svg {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 10px;
    }
    span {
      text-transform: uppercase; 
      font-size: 13px;
    }
    &.black {
      background-color: ${props => props.theme.btnBlackColor} !important;
      border-color: ${props => props.theme.btnBlackColor} !important;
      color: ${props => props.theme.whiteColor} !important;
      text-transform: uppercase;
    }
    &.bold {
      font-weight: 700;
    }
    &.long {
      padding: 3px 40px !important; 
    }
  }
  &.btn-welcome {
    &.menu {
      width: 100%;
      padding: 10px 20px;
      text-align: center;
      margin-bottom: 20px;
      line-height: 1;
      svg {
        font-size: 16px;
        vertical-align: middle;
        display: block;
        float: left;
      }
      a {
        color: ${props => props.theme.blackColor};
      }
      span {
        text-transform: capitalize;
        font-weight: 700;
        font-size: inherit;
        line-height: 16px;
      }
    }
  }
  &.chat {
    svg {
      font-size: 38px;
      vertical-align: middle;
      font-weight: 700;
      margin-right: 10px;
    }
    span {
      font-weight: 700;
      font-size: 13px;
      text-transform: inherit;
    }
  }
  &.btn-menu {
    margin: 0 25px 25px 0;
    width: 45px;
    height: 45px;
    z-index: 9999;
    border: 3px solid ${props => props.theme.blackColor};
    border-radius: 50%;
    background-color: ${props => props.theme.whiteColor};
    transition: all 0.3s ease;
    line-height: 1;
    font-size: 20px;
    font-weight: 700;
    &:focus {
      outline: none;
    }
    &.active {
      transform: rotate(225deg);
    }
    svg {
      color: ${props => props.theme.borderBlack};
    }
  }
`;

export default buttonWelcomeStyles;
