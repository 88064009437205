import styled from 'styled-components';

export const StyledDropZone = styled.div`
	width: 100%;
	min-height: 150px;
	border: 2px dashed #ccc;
	text-align: center;
	background-color: #fafafa;
`;

export const Wrapper = styled.div`
	width: 400px;
`;

export const StyledListFile = styled.div`
	display: inline-block;
	width: 100%;
	text-align: left;
	padding: 4px;
`;

export const AddNewItem = styled.button`
	width: 100px;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #ddd;
  display: inline-block;
  margin: 8px;
  vertical-align: top;
  background-color: #fff;
  svg{
  	font-size: 20px;
    color: #ddd;
  }
`;