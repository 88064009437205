/**
 *
 * BoxShadowWrapper
 *
 */

import React, { memo, Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBoxShadowWrapper = styled.div`
	background-color: #fff;
	box-shadow: 1px 2px 5px 1px rgba(206,206,206,0.5);
`;
function BoxShadowWrapper(props) {
	return (
		<StyledBoxShadowWrapper {...props}> 
			{Children.toArray(props.children)}
		</StyledBoxShadowWrapper>
	);
}

BoxShadowWrapper.propTypes = {};

export default memo(BoxShadowWrapper);
