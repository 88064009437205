/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { memo, useState, useEffect } from 'react';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { HotKeys } from 'react-hotkeys';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { reactLocalStorage } from 'utils';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import history from 'utils/history';

import { LOCAL_STORAGE_ACCOUNT_KEY } from 'containers/App/constants';
import HomePage from 'containers/HomePage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import ForgotPasswordForm from 'containers/ForgotPasswordForm/Loadable';
import CheckNotificationPage from 'containers/CheckNotificationPage/Loadable';
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable';
import BankAccountPage from 'containers/BankAccountPage/Loadable';
import AddBankAccoutPage from 'containers/AddBankAccoutPage/Loadable';
import BillPrintPage from 'containers/BillPrintPage/Loadable';
import CalendarPage from 'containers/JobPage/CalendarPage/Loadable';
import CampaignPage from 'containers/CampaignPage/Loadable';
import AddCampaignPage from 'containers/AddCampaignPage/Loadable';
import CampaignDetailsPage from 'containers/CampaignDetailsPage/Loadable';
import CompanyPage from 'containers/CompanyPage/Loadable';
import AddCompanyPage from 'containers/AddCompanyPage/Loadable';
import ContactPage from 'containers/ContactPage/Loadable';
import AddContactPage from 'containers/AddContactPage/Loadable';
import ContactDetailsPage from 'containers/ContactDetailsPage/Loadable';
import ContactDebtsPage from 'containers/ContactDebtsPage/Loadable';
import ConfigurationPage from 'containers/ConfigurationPage/Loadable';
import InventoryNoteListPage from 'containers/InventoryNoteListPage/Loadable';
import InventoryNotePage from 'containers/InventoryNotePage/Loadable';
import JobListPage from 'containers/JobPage/JobListPage/Loadable';
import AddJobPage from 'containers/AddJobPage/Loadable';
import OpportunityPage from 'containers/OpportunityPage/Loadable';
import AddOpportunityPage from 'containers/AddOpportunityPage/Loadable';
import OrderPage from 'containers/OrderPage/Loadable';
import AddOrderPage from 'containers/AddOrderPage/Loadable';
import PaymentBillsPage from 'containers/PaymentBillsPage/Loadable';
import AddPaymentBillPage from 'containers/AddPaymentBillPage/Loadable';
import PermissionPage from 'containers/PermissionPage/Loadable';
import PermissionManagementPage from 'containers/PermissionManagementPage/Loadable';
import PosSessionDetailsPage from 'containers/PosSessionDetailsPage/Loadable';
import Payment from 'components/Payment';
import ProductPage from 'containers/ProductPage/Loadable';
import ProductDetailsPage from 'containers/ProductDetailsPage/Loadable';
import ProjectPage from 'containers/ProjectPage/Loadable';
import AddProjectPage from 'containers/AddProjectPage/Loadable';
import SalesPage from 'containers/SalesPage/Loadable';
import PosPage from 'containers/PosPage/Loadable';
import AddPosPage from 'containers/AddPosPage/Loadable';
import ResourcesPage from 'containers/ResourcesPage/Loadable';
import AddResourcesPage from 'containers/AddResourcesPage/Loadable';
import PosProductCategorySuggestions from 'containers/PosProductCategorySuggestions/Loadable';
import ApprovePosSessionPage from 'containers/ApprovePosSessionPage/Loadable';
import PosProductCategorySettings from 'containers/PosProductCategorySettings/Loadable';
import ProjectTasksPage from 'containers/ProjectTasksPage/Loadable';
import ReceiptsPage from 'containers/ReceiptsPage/Loadable';
import AddReceiptPage from 'containers/AddReceiptPage/Loadable';
import ReportPage from 'containers/ReportPage/Loadable';
import RmsPage from 'containers/RmsPage/Loadable';
import MailReportPage from 'containers/MailReportPage/Loadable';
import StaffPage from 'containers/StaffPage/Loadable';
import StaffDetailsPage from 'containers/StaffDetailsPage/Loadable';
import StorePage from 'containers/StorePage/Loadable';
import TransferPage from 'containers/TransferPage/Loadable';
import AddStorePage from 'containers/AddStorePage/Loadable';
import UserManagementPage from 'containers/UserManagementPage/Loadable';
import UserProfilePage from 'containers/UserProfilePage/Loadable';
import VendorPage from 'containers/VendorPage/Loadable';
import AddVendorPage from 'containers/AddVendorPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import MaintenancePage from 'containers/MaintenancePage/Loadable';
import VtdLookupPage from 'modules/vtd/containers/VtdLookupPage/Loadable';

import Header from 'components/Header';
import NavBar from 'containers/NavBar/Loadable';
import Button from 'components/Button';
import Footer from 'components/Footer';
import FixedMenu from 'components/FixedMenu';
import SystemAlert from 'components/SystemAlert';
import LoadingOverlayContainer from 'components/LoadingOverlay';
import { defaultTheme } from 'constants/theme';
import Routes from 'constants/routes';
import RedirectOnHome from './redirect';
import {
  loadAuthInLocalStorage,
  setShowRouterPrompt,
  getCustomComplex,
  getConfigs
} from './actions';
import {
  makeSelectAlert,
  makeSelectLocation,
  makeSelectAccount,
  makeSelectLogInStatus,
  makeSelectShowPrompt,
  makeSelectCustomComplex,
  makeSelectConfigs,
} from './selectors';
import saga from './saga';
import reducer from './reducer';
import { Container, Button as ButtonRS, Row } from 'reactstrap';
import messages from 'containers/NavBar/messages';
import appMessages from './messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserFeatures } from 'components/FeatureRender';
import { Utils } from 'utils';

import GlobalStyle from '../../global-styles';
import AddStaffPage from 'containers/AddStaffPage/Loadable';

const AppWrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  
  .overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: all 0.5s ease;
  }
  .padding-top-mobile {
    @media (max-width: 767px) {
      margin-top: 62px;
    }
  }
  .Toastify__toast {
    min-height: 55px;
  }
`;

const keyMap = {
  GO_CONTACT_PAGE: 'f2',
  GO_STAFF_PAGE: 'f4',
  GO_JOB_PAGE: 'f6',
  GO_ORDER_PAGE: 'f8',
};

const key = 'app';

const StyledModalHeader = styled(ModalHeader)`
  background-color: ${props => props.theme.navbarBackgroundColor};
  color: ${props => props.theme.whiteColor};
  text-transform: uppercase;
`;

const StyledModalFooter = styled(ModalFooter)`
  button {
    text-transform: uppercase;
    min-width: 80px;
  }
`;

const BreadCrumbsWrapper = styled.div`
  background-color: white;
  border: solid 1px ${props => props.theme.inputBorder};
  border-radius: 3px;
  & >span{
    display: inline-block;
    position: relative;
    padding: 5px 10px 5px 25px;
    &:first-child{
      padding-left: 10px;
    }
    &:before{
      content: "";  
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-left: 13px solid ${props => props.theme.inputBorder};
      position: absolute; right: -13px; top: 0;
    }
    &:after{
      content: "";  
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-left: 13px solid white;
      position: absolute; right: -12px; top: 0;
    }
  }
`;

export function App({
  alerts,
  location,
  account,
  getAuthInLocalStorage,
  userConfigs,
  loggedIn,
  showPrompt,
  setShowPrompt,
  customComplex,
  getCustomOpportunity,
  getUserConfigs,
  getCustomComplex,
}) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [authentication, setAuthentication] = useState(
    reactLocalStorage.getObject(LOCAL_STORAGE_ACCOUNT_KEY)
  );
  useEffect(() => {
    if (!account || account && _.isEmpty(account)) {
      getAuthInLocalStorage();
    }
    if (loggedIn) {
      setAuthentication(reactLocalStorage.getObject(LOCAL_STORAGE_ACCOUNT_KEY));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (account && !_.isEmpty(account)) {
      getCustomComplex();
      getUserConfigs();
    }
  }, [account]);

  const [isOpen, setIsOpen] = useState(false);
  const alertsProps = {
    alerts,
  };

  let className = ``;
  let classNameMenu = `menu hide`;
  if (isOpen) {
    className = `overlay`;
    classNameMenu = `menu show`;
  }

  const handlers = {
    GO_CONTACT_PAGE: e => history.push('/contact'),
    GO_STAFF_PAGE: e => history.push('/staff'),
    GO_JOB_PAGE: e => history.push('/job'),
    GO_ORDER_PAGE: e => history.push('/order'),
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <AppWrapper>
          <SystemAlert {...alertsProps} />
          <LoadingOverlayContainer/>
          <Helmet
            titleTemplate="%s - VS ERP Solutions"
            defaultTitle="VSS ERP Solutions"
          >
            <meta
              name="description"
              content="VSS ERP Solutions"
            />
          </Helmet>
          <Route path={`/maintenance`} component={MaintenancePage}/>
          <Route path="/login" component={LoginPage} />
          <Route exact path="/password/forgot" component={ForgotPasswordForm} />
          <Route exact path="/password/forgot/notification" component={CheckNotificationPage} />
          <Route path="/password/reset" component={ResetPasswordPage} />
          {((authentication && !_.isEmpty(authentication)) ||
            (account && !_.isEmpty(account))) &&
            location.pathname !== '/api/login/facebook' &&
            location.pathname !== '/login' &&
            location.pathname !== '/password/forgot' &&
            location.pathname !== '/password/reset' &&
            location.pathname !== '/password/forgot/notification' &&
            location.pathname !== '/maintenance' ? (
              <>
                <div className={className} onClick={() => { setIsOpen(!isOpen) }}></div>
                <NavBar data={account} />
                <Breadcrumbs
                  container={BreadCrumbsWrapper}
                  containerProps={{
                    className: 'm-3'
                  }}
                  separator={<span></span>}
                  item={NavLink}
                  finalItem={'span'}
                  finalProps={{
                    style: {}
                  }}
                />
                <UserFeatures roles={authentication.roles} permissions={authentication.permissions} />
                <div className="p-3 padding-top-mobile">
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path={Routes.CONTACT} component={ContactPage} />
                    <Route path={`${Routes.CONTACT_DETAILS}/:id([0-9]+)`} component={ContactDetailsPage} />
                    <Route path={`${Routes.STAFF_DETAILS}/:id([0-9]+)`} component={StaffDetailsPage} />
                    <Route exact path={Routes.CONTACT_NEW} component={AddContactPage} />
                    <Route path={`${Routes.CONTACT_UPDATE}/:id([0-9]+)`} component={AddContactPage} />
                    <Route exact path={Routes.PERMISSION} component={PermissionPage} />
                    <Route path={Routes.PRODUCT} component={ProductPage} />
                    <Route path={`${Routes.PRODUCT_DETAILS}/:id([0-9]+)`} component={ProductDetailsPage} />
                    <Route exact path={Routes.JOB} component={CalendarPage} />
                    <Route exact path={Routes.TASK_LIST} component={JobListPage} />
                    <Route exact path={Routes.TASK_NEW} component={AddJobPage} />
                    <Route path={`${Routes.TASK_LIST}/:id([0-9]+)`} component={AddJobPage} />
                    <Route exact path={Routes.STAFF} component={StaffPage} />
                    <Route exact path={Routes.STAFF_NEW} component={AddStaffPage} />
                    <Route path={`${Routes.STAFF_UPDATE}/:id([0-9]+)`} component={AddStaffPage} />
                    <Route exact path={Routes.VENDOR} component={VendorPage} />
                    <Route exact path={Routes.VENDOR_NEW} component={AddVendorPage} />
                    <Route exact path={Routes.PRODUCT_IMPORT} component={InventoryNotePage} />
                    <Route exact path={Routes.INVENTORY_NOTES} component={InventoryNoteListPage} />
                    <Route exact path={`${Routes.INVENTORY_NOTES}/details/:id([0-9]+)`} component={InventoryNotePage} />
                    <Route path={Routes.SYSTEM} component={UserManagementPage} />
                    <Route exact path={Routes.ORDER} component={OrderPage} />
                    <Route exact path={Routes.ORDER_NEW} component={AddOrderPage} />
                    <Route path={`${Routes.ORDER_UPDATE}/:id([0-9]+)`} component={AddOrderPage} />
                    <Route path={`/systems/role/:id([0-9]+)`} component={PermissionManagementPage} />
                    <Route exact path={`${Routes.VENDOR_UPDATE}/:id([0-9]+)`} component={AddVendorPage} />
                    <Route exact path={Routes.STORE} component={StorePage} />
                    <Route exact path={Routes.STORE_NEW} component={AddStorePage} />
                    <Route path={`${Routes.STORE}/:id([0-9]+)`} component={AddStorePage} />
                    <Route exact path={Routes.COMPANY} component={CompanyPage} />
                    <Route exact path={Routes.COMPANY_NEW} component={AddCompanyPage} />
                    <Route path={`${Routes.COMPANY}/:id([0-9]+)`} component={AddCompanyPage} />
                    <Route path={Routes.REPORT} component={ReportPage} />
                    <Route path={Routes.EMAIL_REPORT} component={MailReportPage} />
                    <Route exact path={Routes.RECEIPT} component={ReceiptsPage} />
                    <Route exact path={`${Routes.RECEIPT}/new`} component={AddReceiptPage} />
                    <Route exact path={Routes.PAYMENT_BILL} component={PaymentBillsPage} />
                    <Route exact path={`${Routes.PAYMENT_BILL}/new`} component={AddPaymentBillPage} />
                    <Route path={Routes.CONFIGURATION} component={ConfigurationPage} />
                    <Route exact path={Routes.OPPORTUNITY} component={OpportunityPage} />
                    <Route exact path={Routes.BANK_ACCOUNT} component={BankAccountPage} />
                    <Route exact path={Routes.CONTACT_DEBTS} component={ContactDebtsPage} />
                    <Route exact path={Routes.BANK_ACCOUNT_NEW} component={AddBankAccoutPage} />
                    <Route exact path={`${Routes.BANK_ACCOUNT}/:id([0-9]+)`} component={AddBankAccoutPage} />
                    <Route exact path={Routes.CAMPAIGN} component={CampaignPage} />
                    <Route exact path={`${Routes.CAMPAIGN}/:id([0-9]+)`} component={AddCampaignPage} />
                    <Route path={`${Routes.CAMPAIGN}/details/:id([0-9]+)`} component={CampaignDetailsPage} />
                    <Route exact path={Routes.CAMPAIGN_NEW} component={AddCampaignPage} />
                    <Route exact path={Routes.PROJECT} component={ProjectPage} />
                    <Route exact path={Routes.SALES} component={SalesPage} />
                    <Route exact path={Routes.PROJECT_NEW} component={AddProjectPage} />
                    <Route exact path={`${Routes.PROJECT}/:id([0-9]+)`} component={AddProjectPage} />
                    <Route path={`${Routes.PROJECT}/:id([0-9]+)/tasks`} component={ProjectTasksPage} />
                    <Route exact path={Routes.POS} component={PosPage} />
                    <Route exact path={Routes.POS_NEW} component={AddPosPage} />
                    <Route exact path={Routes.RESOURCES} component={ResourcesPage} />
                    <Route exact path={Routes.RESOURCES_NEW} component={AddResourcesPage} />
                    <Route exact path={`${Routes.RESOURCES}/:id([0-9]+)`} component={AddResourcesPage} />
                    <Route exact path={`${Routes.POS}/:posId([0-9]+)/session/:sessionId([0-9]+)`} component={PosSessionDetailsPage} />
                    <Route exact path={`${Routes.POS}/:posId/session/:sessionId([0-9]+)/pay`} component={Payment} />
                    <Route exact path={`${Routes.POS}/session/confirm/:id([0-9]+)`} component={ApprovePosSessionPage} />
                    <Route exact path={`${Routes.POS}/:posId/session/:sessionId([0-9]+)/print/:code`} component={BillPrintPage} />
                    <Route exact path={Routes.POS_SETTINGS_CATEGORIES} component={PosProductCategorySettings} />
                    <Route exact path={Routes.POS_SETTINGS_CATEGORIES_SUGGESTIONS} component={PosProductCategorySuggestions} />
                    <Route exact path={Routes.OPPORTUNITY_NEW} component={AddOpportunityPage} />
                    <Route path={`${Routes.OPPORTUNITY}/:id([0-9]+)`} component={AddOpportunityPage} />
                    <Route path={Routes.TRANSFER} component={TransferPage} />
                    <Route path={Routes.LOOKUP} component={VtdLookupPage} />
                    <Route exact path={Routes.RMS} component={RmsPage} />
                    <Route exact path={`${Routes.RMS}/:resourceId([0-9]+)/pay`} component={Payment} />
                    <Route exact path={`/user/profile`} component={UserProfilePage}/>
                    <Route exact path={`${Routes.ERROR}`} component={NotFoundPage} />
                    <Route path="" component={NotFoundPage} />
                  </Switch>
                  <NavigationPrompt when={showPrompt}>
                    {({ onConfirm, onCancel }) => (
                      <Modal isOpen={showPrompt}>
                        <StyledModalHeader className="justify-content-center">Thông báo</StyledModalHeader>
                        <ModalBody className="text-center">
                          <FormattedMessage {...appMessages.promptNav} />
                        </ModalBody>
                        <StyledModalFooter className="justify-content-center">
                          <ButtonRS color="secondary text-center" onClick={onCancel}>Cancel</ButtonRS>
                          <ButtonRS color="primary text-center" onClick={() => { onConfirm(); setShowPrompt(false) }}>OK</ButtonRS>{' '}
                        </StyledModalFooter>
                      </Modal>
                    )}
                  </NavigationPrompt>
                </div>
                <FixedMenu classNameMenu={classNameMenu} isOpen={isOpen} setIsOpen={setIsOpen} />
              </>
            ) : (
              <RedirectOnHome location={location} />
            )}
          <GlobalStyle />
        </AppWrapper>
      </HotKeys>
    </ThemeProvider>
  );
}

App.propTypes = {
  alerts: PropTypes.any,
  getAuthInLocalStorage: PropTypes.func,
  account: PropTypes.any,
  location: PropTypes.any,
  authentication: PropTypes.any,
  loggedIn: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  alerts: makeSelectAlert(),
  location: makeSelectLocation(),
  account: makeSelectAccount(),
  loggedIn: makeSelectLogInStatus(),
  showPrompt: makeSelectShowPrompt(),
  userConfigs: makeSelectConfigs(),
  customComplex: makeSelectCustomComplex(),
});

export function mapDispatchToProps(dispatch) {
  return {
    getAuthInLocalStorage: () => {
      dispatch(loadAuthInLocalStorage());
    },
    setShowPrompt: (show) => {
      dispatch(setShowRouterPrompt(show));
    },
    getCustomComplex: () => {
      dispatch(getCustomComplex());
    },
    getUserConfigs: (params) => {
      dispatch(getConfigs(params));
    },
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(App);
