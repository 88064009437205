/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = state => state.global || initialState;

const selectRouter = state => state.router;

const makeSelectAccount = () =>
  createSelector(
    selectGlobal, 
    globalState => globalState.account,
  );
  
const makeSelectAlert = () =>
  createSelector(
    selectGlobal, 
    globalState => globalState.systemAlert,
  );

const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.loading,
  );

const makeSelectLogInStatus = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.loggedIn,
  );

const makeSelectError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.error,
  );

const makeSelectLocation = () =>
  createSelector(
    selectRouter,
    routerState => routerState.location,
  );

const makeSelectShowPrompt = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.showPrompt,
  );

const makeSelectCustomOpportunity = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.customOpportunityList,
  );

const makeSelectConfigs = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.userConfigs,
  );

const makeSelectCustomComplex = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.customComplex,
  );

const makeSelectLoadingOverlay = () =>
  createSelector(
    selectGlobal, 
    globalState => globalState.loadingOverlay,
  );

export {
  selectGlobal,
  makeSelectAccount,
  makeSelectLoading,
  makeSelectError,
  makeSelectAlert,
  makeSelectLocation,
  makeSelectLogInStatus,
  makeSelectShowPrompt,
  makeSelectCustomOpportunity,
  makeSelectConfigs,
  makeSelectCustomComplex,
  makeSelectLoadingOverlay,
};
